/**
 * Application constants scope
 * @author Anatoli Deryshev
 */
/**
 * Connection timeout configuration
 */
Ext.Ajax.defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json;charset=utf-8'
};
//если в админке передаем ?local_login=true везде добвить заголовок SSO_LOCAL_LOGIN
if (
	buildVersion.isAdmin &&
	Ext.urlDecode(location.search.substring(1)) &&
	Ext.urlDecode(location.search.substring(1)).local_login
) {
	Ext.Ajax.defaultHeaders['SSO_LOCAL_LOGIN'] = true;
}

Ext.Ajax.setDefaultHeaders(Ext.Ajax.defaultHeaders);
Ext.Ajax.timeout = 300000; //300 seconds
//Ext.override(Ext.form.Basic, {
//	timeout: Ext.Ajax.timeout / 1000
//});
//Ext.override(Ext.data.proxy.Server, {
//	timeout: Ext.Ajax.timeout
//});
//Ext.override(Ext.data.Connection, {
//	timeout: Ext.Ajax.timeout
//});

Ext.namespace('edi.constants');
edi.constants.DEFAULT = {
	COMPONENTS_SETTINGS: {
		GRID: {}
	},
	BACKGROUND: {
		ON_START: false,
		ROTATION: 'LIST',
		IMG_LIST: [],
		BASE_CLASS: 'edi-viewport-with-background',
		CLASS_NAME_PREFIX: 'edi-viewport-background-',
		COOKIE_NAME: 'bg-nr'
	},
	LOGIN_FORM_HIDE_MASK: true,
	UPDATE_TIMEOUT: 15000, // auto grid reload timeout
	AJAX_METHOD: 'GET',
	CURRENCY: 'RUB',
	CURRENCY_CODE: 643,
	COUNTRY: 'RU',
	COUNTRY_ISO_3: '643',
	LANGUAGE: 'RUSSIAN',
	LANGUAGE_COOKIE_NAME: 'selectedLanguage',
	USER_GROUP: 'default',
	PRECISION_NUMBER: 4,
	SUMM_DISPLAY_PRECISION_NUMBER: 2,
	TREE_DEPTH: 2,
	PAGING_BAR_ITEMS: [
		{
			size: 15
		},
		{
			size: 25
		},
		{
			size: 50
		},
		{
			size: 100
		}
	],
	MODAL: {
		WIDTH_MINIMUM: 350,
		WIDTH: 400,
		WIDTH_MEDIUM: 500,
		WIDTH_LARGE: 600,
		WIDTH_LARGER: 800,
		WIDTH_MAX: 1000,
		WIDTH_MOST_MAX: 1150,
		HEIGHT_MINIMUM: 200,
		HEIGHT: 200,
		HEIGHT_LARGE: 350,
		HEIGHT_LARGER: 500
	},
	FORM_GRID: {
		SCROLLABLE_10: 540,
		SCROLLABLE_EDIT: 475,
		SCROLLABLE_DESADV: 410
	},
	VIEWPORT: {
		layout: 'border',
		minWidth: 1260,
		minHeight: 600,
		cls: 'edi-viewport',
		listeners: {
			boxready: function (comp) {
				var size = Ext.getBody().getSize();
				if (
					size.width < edi.constants.DEFAULT.VIEWPORT.minWidth ||
					size.height < edi.constants.DEFAULT.VIEWPORT.minHeight
				) {
					//Force resize on initial loading
					comp.setSize(size.width, size.height);
				}
			}
		}
	},
	NAVIGATION_HEADER_CONFIG: {
		width: 256,
		autoScroll: true,
		header: {
			cls: 'edi-navigation-menu-header'
		},
		cls: 'edi-navigation-menu with-shadow'
	},
	TABPANEL_HEADER_CONFIG: {
		margin: '20 20 0 20'
	},
	HEADER_CONFIG: {
		region: 'north',
		height: 60,
		minHeight: 34,
		cls: 'edi-main-header-panel',
		border: false,
		animCollapse: false,
		bodyPadding: 0
	},
	CREATE_BUTTON: {
		xtype: 'container',
		widthRatio: 0.5,
		minWidth: 140,
		minHeight: 64,
		layout: 'fit',
		heightRatio: 0.42
	},
	PANEL: {
		border: 0
	},
	REST_PREFIX: '/secure/edi/logic_engine/api/',
	WS_PREFIX: '/secure/edi/logic_engine/ws/',
	ICONCLS: {
		COLUMN_ICONS: {
			DEFAULT: 'edi-action-column',
			SEND: 'edi-action-column edi-action-column-send',
			COPY: 'edi-action-column edi-button-copy',
			DETAILS: 'edi-action-column edi-action-column-details',
			EDIT: 'edi-action-column edi-action-column-edit',
			REMOVE: 'edi-action-column edi-action-column-remove',
			DOWNLOAD: 'edi-action-column edi-action-column-download',
			PRINT: 'edi-action-column edi-action-column-print',
			VIEW: 'edi-action-column edi-action-column-view',
			VERSION: 'edi-action-column edi-action-column-version',
			FINANCE: 'edi-action-column edi-action-column-finance',
			UP: 'edi-action-column edi-action-column-up',
			DOWN: 'edi-action-column edi-action-column-down',
			ERROR: 'edi-action-column edi-action-column-error-description',
			ADD: 'edi-action-column edi-action-column-add',
			MOVE: 'edi-action-column edi-action-column-move',
			LOCK: 'edi-action-column edi-action-column-lock',
			UNLOCK: 'edi-action-column edi-action-column-unlock',
			RESTORE: 'edi-action-column edi-action-column-restore'
		}
	},
	FILTER: {
		FIELDS: {
			DOC_DATE: 'docDateRange',
			CREATION_DATE: 'creationDateRange',
			MODIFY_DATE: 'modifyDateRange'
		},
		PERIODS: {
			TODAY: 'today',
			WEEK: 'week',
			MONTH: 'month',
			QUARTER: 'quarter',
			LAST7DAYS: 'last7days',
			LAST14DAYS: 'last14days',
			LAST30DAYS: 'last30days',
			LAST90DAYS: 'last90days'
		},
		CUSTOM_PERIODS: {
			last7days: 7,
			last14days: 14,
			last30days: 30,
			last90days: 90
		},
		CLOSE_TIMES: [0.5, 0.8, 1, 1.5, 2, 2.5, 3],
		FIRE_SEARCH_DELAY: 500
	}
};

edi.constants.DEFAULT.COMPONENTS_SETTINGS.HEADER_BAR_LOGO = '<div class="ui-header-logo"></div>';
//название модуля куда должен вести клик по лого
edi.constants.DEFAULT.COMPONENTS_SETTINGS.HEADER_BAR_LOGO_MODULE_CLICK = null;

edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.TOP_BAR = {
	CLS: 'edi-grid-top-bar',
	OTHER_BTN_MENU_CLS: 'edi-grid-top-bar-other-actions-menu',
	VISIBLE_BTNS: 3
};
edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.COLUMN_MIN_WIDTH = 100;
edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.SHOW_SETTING_BUTTON = true;

edi.constants.DEFAULT.NAVIGATION_V2_CONFIG = {
	region: 'west',
	collapsible: false
};

Object.assign(edi.constants, {
	SESSION_KEEP_ALIVE: true, //works only in debug mode
	SESSION_KEEP_ALIVE_TIMEOUT: 300000,
	AUTH_TYPE_DEV: 'EDI',
	AUTH_TYPE: 'EDI',
	AUTH_TYPE_BASIC: 'BASIC YWNjb3VudF9hZG1pbl9sb2dpbjpGfi8oe0h7KngzKUh2KW0=',
	DISABLE_EXTRA_DATA: false,
	PRODUCTION_SERVERS: [/\.alfabank\.ru/, /\.factorplat\.ru/, /\.ediweb\.ru/],
	TEST_SERVERS: [/10\.44\.1\.65/, /10\.44\.1\.4/],
	LANGUAGES: {
		RUSSIAN: {
			CODE: 'ru',
			CURRENCY: 'RUB',
			EXT_LOCALE: 'ru',
			AVAILABLE_IN_UI: true
		},
		ENGLISH: {
			CODE: 'en',
			CURRENCY: 'EUR',
			EXT_LOCALE: 'en',
			AVAILABLE_IN_UI: true
		},
		JAPAN: {
			CODE: 'jp',
			EXT_LOCALE: 'jp',
			CURRENCY: 'JPY'
		},
		ESTONIAN: {
			CODE: 'ee',
			CURRENCY: 'EUR',
			EXT_LOCALE: 'et',
			AVAILABLE_IN_UI: true
		},
		LATVIAN: {
			CODE: 'lv',
			CURRENCY: 'EUR',
			EXT_LOCALE: 'lv',
			AVAILABLE_IN_UI: true
		},
		LITHUANIAN: {
			CODE: 'lt',
			CURRENCY: 'EUR',
			EXT_LOCALE: 'lt',
			AVAILABLE_IN_UI: true
		},
		BELARUSIAN: {
			CODE: 'by',
			CURRENCY: 'BYN'
		},
		UKRAINIAN: {
			CODE: 'ua',
			CURRENCY: 'UAH',
			EXT_LOCALE: 'ukr',
			AVAILABLE_IN_UI: true
		},
		KAZAKH: {
			CODE: 'kz',
			CURRENCY: 'KZT'
		}
	},
	COUNTRIES: {
		RUS: {
			LANGUAGE: 'RUSSIAN',
			CURRENCY: 'RUB'
		},
		LVA: {
			LANGUAGE: 'LATVIAN',
			CURRENCY: 'EUR'
		},
		EST: {
			LANGUAGE: 'ESTONIAN',
			CURRENCY: 'EUR'
		},
		LTU: {
			LANGUAGE: 'LITHUANIAN',
			CURRENCY: 'EUR'
		},
		BLR: {
			LANGUAGE: 'RUSSIAN',
			CURRENCY: 'BYN'
		},
		UKR: {
			LANGUAGE: 'RUSSIAN',
			CURRENCY: 'UAH'
		},
		KAZ: {
			LANGUAGE: 'RUSSIAN',
			CURRENCY: 'KZT'
		},
		OTHER: {
			LANGUAGE: 'ENGLISH',
			CURRENCY: 'EUR'
		}
	},
	BUSINESS_PROCESS_PROPERTIES: {
		DELETE: 'DELETE',
		REJECT: 'REJECT',
		REJECT_REASON: 'REJECT_REASON',
		RECEIVER_REJECT: 'RECEIVER_REJECT',
		SENDER_REJECT: 'SENDER_REJECT',
		REVOKE: 'REVOKE',
		REVOKE_REASON: 'REVOKE_REASON'
	},
	ICONS: {
		ADD: 'xe148@Material Icons',
		AGREEMENT: 'xe001@Material Icons',
		ANIMATION: 'xe149@Material Icons',
		ANNOUNCEMENT: 'xe85a@Material Icons',
		APPROVE: 'xe876@Material Icons',
		ARCHIVE: 'xe149@Material Icons',
		ARROW_BACK: 'xe5c4@Material Icons',
		ARROW_FORWARD: 'xe5c8@Material Icons',
		ARROW_UP: 'xe5c7@Material Icons',
		ARROW_UP_LARGE: 'xe316@Material Icons',
		ARROW_NAVIGATE_NEXT: 'xe315@Material Icons',
		ARROW_DOWN_LARGE: 'xe313@Material Icons',
		ARROW_DOWN: 'xe5c5@Material Icons',
		ARROW_DOWN_COMBO: 'xe313@Material Icons',
		ARROW_UP_DOWN: 'xe5d7@Material Icons',
		ARROW_DOWN_UP: 'xe5d6@Material Icons',
		ASSIGNMENT: 'xe001@Material Icons',
		BRIEFCASE: 'xe8f9@Material Icons',
		BOOK_OPEN: 'xe86d@Material Icons',
		BUILD: 'xe869@Material Icons',
		CALENDAR_CLOCK: 'xe8df@Material Icons',
		CALL_MADE: 'xe0b2@Material Icons',
		CANCEL: 'xe5c9@Material Icons',
		CHECK_LIST: 'xe065@Material Icons',
		CHECK_CIRCLE: 'xe5ca@Material Icons',
		CHECK_ROUNDED: 'xe86c@Material Icons',
		CHART: 'xe501@EdiSoftIcons',
		CLASS: 'xe865@Material Icons',
		CLIPBOARD_ALERT: 'xe85f@Material Icons',
		CLOUD_DOWNLOAD: 'xe2c0@Material Icons',
		CLOUD_UPLOAD: 'xe2c3@Material Icons',
		CLOSE: 'xe14c@Material Icons',
		CODE: 'xe86f@Material Icons',
		CONTRACT: 'xe873@Material Icons',
		CONTRACT_REVOKE: 'xe000@Material Icons',
		COPY: 'xe14d@Material Icons',
		CREATE: 'xe873@Material Icons',
		DATE_RANGE: 'xe916@Material Icons',
		DASHBOARD: 'xe871@Material Icons',
		DEBTOR: 'xe563@Material Icons',
		DELETE: 'xe872@Material Icons',
		DETAILS: 'xe417@Material Icons',
		DIFF: 'xe915@Material Icons',
		DONE: 'xe5ca@Material Icons',
		DONE_ALL: 'xe877@Material Icons',
		DRAFT: 'xe22b@Material Icons',
		EDIT: 'xe150@Material Icons',
		EXPORT_DATASOURCE: 'xe169@Material Icons',
		EXTENSION: 'xe87b@Material Icons',
		EVENT_NOTE: 'xe616@Material Icons',
		FACTOR: 'xe84f@Material Icons',
		FILE_DOWNLOAD: 'xe2c4@Material Icons',
		FILTER_LIST: 'xe152@Material Icons',
		MODULE_FILTER: 'xe502@EdiSoftIcons',
		MODULE_FILTER_CLEAR: 'xe418@Material Icons',
		FINANCING_MAX: 'xe506@EdiSoftIcons',
		FINANCING_CIRCLE: 'xe506@EdiSoftIcons',
		FINANCING_RUB: 'xe506@EdiSoftIcons',
		FLAG: 'xe153@Material Icons',
		FOLDER: 'xe2c7@Material Icons',
		GRID: 'xe3ec@Material Icons',
		GRID_OFF: 'xe3eb@Material Icons',
		HAND: 'xe925@Material Icons',
		HELP: 'xe887@Material Icons',
		HELP_OUTLINE: 'xe8fd@Material Icons',
		HIGHLIGHT_OFF: 'xe888@Material Icons',
		HOURGLASS: 'xe88b@Material Icons',
		IMPORT_EXPORT: 'xe8d5@Material Icons',
		INBOX: 'xe8d7@Material Icons',
		INFO: 'xe88e@Material Icons',
		INFO_OUTLINE: 'xe88f@Material Icons',
		INTEGRATION: 'xe043@Material Icons',
		KEYBOARD_ARROW_DOWN: 'xe313@Material Icons',
		KEYBOARD_ARROW_LEFT: 'xe314@Material Icons',
		KEYBOARD_ARROW_RIGHT: 'xe315@Material Icons',
		LIBRARY_ADD: 'xe02e@Material Icons',
		LIBRARY_BOOKS: 'xe02f@Material Icons',
		LIST: 'xe896@Material Icons',
		LOGOUT: 'xe879@Material Icons',
		LOCK: 'xe897@Material Icons',
		LOCK_OUTLINE: 'xe899@Material Icons',
		LOCK_OPEN: 'xe898@Material Icons',
		MARKER: 'xe55f@Material Icons',
		MATCHING: 'xe8dd@Material Icons',
		MENU: 'xe5d2@Material Icons',
		MORE_HORIZ: 'xe5d3@Material Icons',
		MORE_VERT: 'xe5d4@Material Icons',
		MOVE_TO_INBOX: 'xe168@Material Icons',
		NOTIFICATIONS: 'xe7f4@Material Icons',
		NOTIFICATIONS_OFF: 'xe7f6@Material Icons',
		MULTI_MODE: 'xe8cd@Material Icons',
		MULTI_MODE_OFF: 'xe92a@Material Icons',
		OPEN_IN_BROWSER: 'xe89d@Material Icons',
		IMPORT_INCLUDE: 'xe0Df@Material Icons',
		ORGANISATION: 'xe7f1@Material Icons',
		PEOPLE: 'xe7ef@Material Icons',
		PERCENT: '37@Material Icons',
		PLAY_ARROW: 'xe037@Material Icons',
		PLAYLIST_ADD: 'xe03b@Material Icons',
		PLUS: 'xe145@Material Icons',
		PRINT: 'xe8ad@Material Icons',
		READ: 'xe151@Material Icons',
		REFRESH: 'xe028@Material Icons',
		RELOAD: 'xe042@Material Icons',
		REVERT_RELOAD: 'xe5d5@Material Icons',
		REJECT: 'xe000@Material Icons',
		REMOVE: 'xe15c@Material Icons',
		REMOVE_OUTLINE: 'xe15d@Material Icons',
		REPAYMENT: 'xe506@EdiSoftIcons',
		REPEAT: 'xe040@Material Icons',
		REPLY: 'xe15e@Material Icons',
		REPLY_ALL: 'xe15f@Material Icons',
		REPORT: 'xe160@Material Icons',
		RESET_ECD_VERSION: 'xe042@Material Icons',
		RESTORE: 'xe8b3@Material Icons',
		ROTATE_RIGHT: 'xe41a@Material Icons',
		SAVE: 'xe161@Material Icons',
		SEARCH: 'xe8b6@Material Icons',
		SECURITY: 'xe32a@Material Icons',
		SEND: 'xe163@Material Icons',
		SEND_APPROVE: 'xe560@Material Icons',
		SETTINGS: 'xe8b8@Material Icons',
		SETTINGS_BACKUP_RESTORE: 'xe042@Material Icons',
		SHOW_LOGS: 'xe1b2@Material Icons',
		SIGN: 'xe500@EdiSoftIcons',
		SIGN_NEW: 'xe900@EdiSoftIcons',
		STAR: 'xe838@Material Icons',
		STOP: 'xe047@Material Icons',
		SORT: 'xe503@EdiSoftIcons',
		SUPPLIER: 'xe558@Material Icons',
		SYNC_PROBLEM: 'xe629@Material Icons',
		TERMS: 'xe8ef@Material Icons',
		USER_CIRCLE: 'xe853@Material Icons',
		VERIFIED_USER: 'xe8e8@Material Icons',
		VIEW_LIST: 'xe8ef@Material Icons',
		VIEW_MODULE: 'xe8f0@Material Icons',
		VISIBILITY: 'xe417@Material Icons',
		VISIBILITY_OFF: 'xe8f5@Material Icons',
		WARNING: 'xe002@Material Icons',
		DOCX: 'xe901@EdiSoftIcons',
		PNG: 'xe410@Material Icons',
		PDF: 'xe415@Material Icons',
		XLS: 'xe902@EdiSoftIcons',
		AUTO_FIX: 'xe8ce@Material Icons',
		CONNECTION: 'xe1e2@Material Icons',
		TABLET: 'xe85d@Material Icons',
		TOPIC: 'xf1c8@Material Icons'
	},
	ADDITIONAL_ACTIONS_ICONS_HANDLERS: [],
	/**
	 * Iterates all icons handler until one of them do not return icon class
	 * @param action
	 * @returns {string}
	 */
	ADDITIONAL_ACTIONS_ICONS_HANDLER: function (action) {
		var icon = '',
			i;
		for (i = 0; i < edi.constants.ADDITIONAL_ACTIONS_ICONS_HANDLERS.length; i++) {
			icon = edi.constants.ADDITIONAL_ACTIONS_ICONS_HANDLERS[i](action);
			if (icon) {
				break;
			}
		}
		return icon;
	},
	AUTO_CHECK_SCOPE_SHARING: true,
	AUTO_CHECK_SCOPE_SHARING_INTERVAL: 3000,
	LIST_ROOT_PROPERTY: 'items',
	LIST_TOTAL_PROPERTY: 'total',
	MENU_PREFIX: 'edi-menu',
	CATEGORY_PREFIX: 'edi-cat',
	ID_PREFIX: 'edi-gen',
	REQUESTS_COUNTER_ATTR: 'edi-requests-counter',
	RENDERED_CONTENT_ATTR: 'edi-content-rendered',
	RENDERED_UI_ATTR: 'edi-ui-rendered',
	CURRENT_MODULE_STATE: 'edi-ui-module-state',
	JS_PATH: 'js/',
	BUTTON_MULTI_CLICK_DELAY: 600,
	ITEMS_PER_PAGE: 25,
	MAX_PAGE_SIZE: 10000,
	DOWNLOAD: {
		FILE_ID_PROPERTY: 'downloadFileId',
		STATUS_PROPERTY: 'downloadFileStatus',
		TIMEOUT: 300000,
		INTERVAL: 500
	},
	AJAX: {
		TIMEOUT: Ext.Ajax.timeout,
		DISABLE_CACHING: true
	},
	LOGIN_TIMEOUT: 30000,
	LOGIN_TIMEOUT_COOKIE_NAME: 'loginTimeout',
	LOGIN_ATTEMPTS: 3,
	LOGIN_FORM_CRD_SAVE_METHOD: 'GET',
	ORG_SELECTION_CHECK_TIMEOUT: 300000, //300sec
	USE_USER_ORG_COOKIE: true,
	ORG_COOKIE_NAME: 'selectedOrganization',
	LOG_UA_DATA: false,
	LOG_UA_PRODUCT: '',
	CHECK_EMPTY_PERMISSIONS: true,
	WEEK_IN_MS: 604800000,
	DAY_IN_MS: 86400000,
	SEARCH_DAY_IN_MS: 86399000,
	HOUR_IN_MS: 3600000,
	MINUTE_IN_MS: 60000,
	PASSWORD: {
		VALIDATORS: [/^[\dA-Za-z!@#$%]+$/, /.{7,}/, /[A-Z]+/, /[a-z]+/, /\d+/, /[!@#$%]+/],
		MIN_LENGTH: 10,
		LIFE_TIME: {
			DAYS_30: 'D30',
			DAYS_60: 'D60',
			DAYS_90: 'D90',
			UNLIMITED: 'UNLIMITED',

			DAYS_COUNT: {
				D30: 30,
				D60: 60,
				D90: 90
			}
		}
	},
	VALIDATORS: {
		EMAIL: /^[0-9A-Z-._+]+@[0-9A-Z-.]+\.[A-Z]{2,20}$/i,
		PHONE: /^([+][0-9]{1,4}([ ])?)?[0-9]{2,16}$/,
		TIME: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
		INT: /^\d+$/,
		FLOAT: /^\d+(\.\d+)?$/,
		NON_WORD_HYPHEN: /[^\w-]/,
		NON_WORD_HYPHEN_SPACE: /[^\w\s-]/,
		INT_NEG: /^(-)?\d+$/,
		MONEY: /^\d+(\.\d{2})?$/,
		ORG_INN: /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{8}$/,
		INDI_INN: /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{10}$/,
		INN: /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})([0-9]{8}|[0-9]{10})$/,
		MASK_RE_INN: /\d/i,
		UNP_BLR_INN: /^([A-Za-zёа-зй-шы-яЁА-ЗЙ-ШЫІіЎў0-9]{9})$/,
		MASK_RE_UNP_BLR_INN: /[A-Za-zёа-зй-шы-яЁА-ЗЙ-ШЫІіЎў0-9]/i,
		GLN: /^\d{13}$/,
		EAN: /^(\d{8}|\d{13})$/,
		PACKAGE_DOC_NUMBER: /^([0-9A-Z]*)[a-zA-Z0-9А-Яа-яёЁ]+/,
		EAN13: /^(\d{0,13})$/,
		EAN14: /^(\d{0,14})$/,
		KPP: /^\d{4}(\d|[A-Z]){2}\d{3}$/i,
		TAX_ID: /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})([0-9]{8}|[0-9]{10})$/i,
		ZIP: /^([a-zA-Z]{2})?\d{5,6}$/,
		ZIP_RUSSIA: /^\d{6}$/,
		ZIP_XSD: /^\d{0,9}$/,
		OKPO: /^(\d{8}|\d{10})$/i,
		OKATO: /^(\d{8}|\d{11})$/i,
		OKTMO: /^(\d{8}|\d{11})$/i,
		OGRN: /^[0-9]{13}$/i,
		FNS_INVOICE_INFO_FIELD: /^[0-9A-Z-–—_№\\\/:]+$/i,
		ALCOHOL_CODE_SKIP_LICENSE: /^26[0-9]$|^5[0-9]0$/,
		BIK: /^[0-9]{9}$/,
		BANK_ACC: /^[0-9]{20}$/,
		PRICE: /^\d+(\.\d+)?$/,
		FNS_ID: /^2[A-Z]{2}[a-zA-Z0-9-]{0,43}$/,
		EANCOM_PRICE: /^[\d]{1,13}(\.[\d]{1,2})?$/,
		DECIMAL_FOUR_AFTER_POINT: /^[\d]{0,6}(\.[\d]{0,4})?$/,
		BARCODE: /^[0-9]{0,14}$/,
		SPACES: /^(\s)+$/,
		SWIFT: /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}[XXX0-9]{0,3}/,
		INDI_OGRN: /^[0-9]{15}$/i,
		GUID: /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i
	},
	VALIDATORS_MULTI: {
		RU: {
			ORG_INN: /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{8}$/,
			INDI_INN: /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{10}$/,
			FOREIGN_INN: /^([0-9]{1,255})$/,
			UNP_BLR_INN: /^([A-Za-zёа-зй-шы-яЁА-ЗЙ-ШЫІіЎў0-9]{9})$/,
			GLN: /^\d{13}$/,
			KPP: /^\d{4}(\d|[A-Z]){2}\d{3}$/i,
			REG_NR: /^(\d{13}|\d{15})$/,
			OKATO: /^(\d{8}|\d{11})$/i,
			OKTMO: /^(\d{8}|\d{11})$/i,

			/* все что ниже из Masterdata */
			REG_NR_LEGAL: /^(\d{13})$/,
			REG_NR_INDI: /^(\d{15})$/,
			OKPO: /^(\d{8}|\d{10})$/i,
			OKPO_LEGAL: /^(\d{8})$/i,
			OKPO_INDI: /^(\d{10})$/i,
			SNILS: /^\d{3}-\d{3}-\d{3}\s\d{2}$/i
		},
		BLT: {
			ORG_INN: /^(.*)$/,
			INDI_INN: /^(.*)$/,
			UNP_BLR_INN: /^([A-Za-zёа-зй-шы-яЁА-ЗЙ-ШЫІіЎў0-9]{9})$/,
			GLN: /^\d{13}$/,
			KPP: /^(.*)$/,
			REG_NR: /^(.*)$/,
			OKATO: /^(\d{8}|\d{11})$/i,
			OKTMO: /^(\d{8}|\d{11})$/i,

			/* все что ниже из Masterdata */
			BANK_ACC_NUMBER21: /^(\d|[A-Z]){21}$/i,
			BANK_ACC_NUMBER20: /^(\d|[A-Z]){20}$/i
		},
		UA: {
			GLN: /^\d{13}$/,
			KPP: /^(.*)$/,
			REG_NR: /^(.*)$/,
			OKATO: /^(\d{8}|\d{11})$/i,
			OKTMO: /^(\d{8}|\d{11})$/i,

			/* все что ниже из Masterdata */
			ORG_INN: /^[0-9]{11,13}$/i,
			INDI_INN: /^[0-9]{10,13}$/i,
			UNP_BLR_INN: /^([A-Za-zёа-зй-шы-яЁА-ЗЙ-ШЫІіЎў0-9]{9})$/,
			REG_NR_EDRPOU: /^[0-9]{8,13}$/i
		}
	},
	POLICY: {
		ALLOW: 'ALLOW',
		RESTRICT: 'RESTRICT'
	},
	STATUS: {
		OK: '200',
		NOT_AUTHORISED: '401',
		FORBIDDEN: '403',
		INVALID_JSON: '999',
		SERVER_ERROR: '999',
		UNEXPECTED_ERROR: '999',
		MAINTENANCE_MODE: '503'
	},
	DATE_FORMAT: {
		SERVER: 'server',
		CLIENT: 'Y-m-d', //ISO8601Short
		DOCUMENT: 'Y-m-d H:i:s', //ISO8601Long
		YEAR: 'Y',
		FNS: 'd.m.Y',
		DATE_TIME: 'Y-m-d H:i:s',
		DATE_TIME_XSD: 'd.m.Y H.i.s',
		DATE_TIME_NO_S: 'Y-m-d H:i',
		DATE_TIME_FNS: 'd.m.Y H:i:s',
		DATE_TIME_FNS_NO_S: 'd.m.Y H:i',
		FULL_DATE_TIME: 'l, F d, Y g:i:s A',
		LONG_DATE: 'l, F d, Y',
		SHORT_DATE: 'n/j/Y',
		TIMESTAMP: 'U',
		XS_DATE_TIME: 'Y-m-dTH:i:s',
		TIME: 'H:i:s',
		TIME_XSD: 'H.i.s',
		DATE_TIME_PRICAT_EANCOM: 'Ymd',
		MILLISECONDS: 'time'
	},
	BACKEND_MODULE_TYPES: {
		MODULE: 'MODULE',
		ARTIFACT: 'ARTIFACT',
		LIB: 'LIB',
		LIBS: 'LIBS'
	}
});

edi.constants.DEFAULT.ERROR_TYPES = ['controller.ediobject.forbidden.action.error'];

edi.constants.RESTORE_FILTER_VALUES_ENABLED = true;

edi.constants.cryptoProIgnoredErrorCodes = ['0x000004C7'];
edi.constants.ENABLE_CERTIFICATE_APPLICATION_CHECKING = false;
edi.constants.ENABLE_POA_FOR_ALL_CERT = false;
edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS = 'test-field-block';

//типы ошибок для метода edi.methods.application_to_fns.signApplicationAndCert чтобы не вызывалось лишних окон
edi.constants.SILENT_TYPE_ERRORS = [
	'certificate.data.not.matched.with.organization',
	'certificate.validator.employee.sign.verification.failed.error'
];
