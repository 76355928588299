import { SignProcess } from './signature/signProcess';

Ext.namespace('edi.utils');
edi.utils = new (function () {
	var __self = this;
	this.setScrollTopAfterFocus = (form) => {
		const formScrollY = form.getScrollY();
		if (form.scrollable && formScrollY !== form.scrollable.trackingScrollTop) {
			// При скролле колесиком Ext запоминает текущее положение скролла и записывает в trackingScrollTop (см. doOnDomScrollEnd)
			// при фокусе события скролла может не произойти т.к. перед этим мог быть вызван restoreState с updateLayout (см onMandatory у fieldError)
			// и updateDomScrollPosition будет silent (см restoreTimer) и НЕ вызовется doOnDomScrollEnd с изменением trackingScrollTop
			// руками запишем текущее положение скролла формы в trackingScrollTop
			// иначе при любом updateLayout Ext проскроллит форму до места, где был скролл до фокуса
			form.scrollable.trackingScrollTop = form.getScrollY();
		}
	};
	/**
	 * find tab with invalid field and set active
	 */
	this.setFocusToTab = function (invalidField) {
		let tab = invalidField.up('panel[panelType="tabpanel"]');
		let tabPanel = tab.up('[xtype="tabpanel"]');
		if (tabPanel && tab) {
			tabPanel?.setActiveTab(tab);
		}
	};
	/**
	 * find composite field with error in array company composite fields
	 * @param    {Array}        excludedIds        id to exclude in search
	 * @param    {Object}       form               form with fields
	 * @param    {Array}        compositeFields    array company composite fields
	 * @param    {Object}       grid               form grid
	 * @param    {boolean}       gridEmptyCondition condition for empty grid
	 * @returns  {Object}
	 */
	this.setFocusToDocumentsWithGrid = function (
		form,
		excludedIds = [],
		compositeFields = [],
		grid,
		gridEmptyCondition = false,
		tabFocus
	) {
		let field = edi.utils.searchErrorField(form, excludedIds, tabFocus);

		if (field) {
			this.setScrollTopAfterFocus(form);
			return;
		}
		if (grid) {
			compositeFields.push(grid);
		}
		field = edi.utils.searchErrorCompositeField(compositeFields, tabFocus);
		if (field) {
			this.setScrollTopAfterFocus(form);
			return;
		}
		if (grid && gridEmptyCondition) {
			if (tabFocus) {
				this.setFocusToTab(grid);
			}
			setTimeout(function () {
				grid.focus();
				edi.utils.setScrollTopAfterFocus(form);
			}, 0);
			return;
		}
		return true;
	};
	/**
	 * find composite field with error in array company composite fields
	 * @param    {Array}        fields        array company composite fields
	 * @returns  {Object}
	 */
	this.searchErrorCompositeField = function (fields = [], tabFocus) {
		var field = fields.find((field) => {
			if (field && !field.isDisabled() && field.isVisible() && field.isValid && !field.isValid()) {
				return field;
			}
			return;
		});
		if (field) {
			if (tabFocus) {
				this.setFocusToTab(field);
			}
			setTimeout(function () {
				field.focus();
			}, 0);
		}
		return field;
	};
	/**
	 * find field with error in array fields
	 * @param    {Array}        excludedIds        id to exclude in search
	 * @param    {Object}       form               form with fields
	 * @returns    {Object}
	 */
	this.searchErrorField = function (form = {}, excludedIds = [], tabFocus) {
		let fields = form.getForm().getFields().items;
		var field = fields.find((field) => {
			let id = null;
			if (field && field.getBubbleParent && field.getBubbleParent().getItemId && excludedIds) {
				id = field.getBubbleParent().getItemId();
			}
			if (id && excludedIds.includes(id)) {
				return;
			}

			if (field.isVisible() && !field.isValid()) {
				return field;
			}
		});
		if (field) {
			if (tabFocus) {
				this.setFocusToTab(field);
			}
			setTimeout(function () {
				field.focus();
			}, 0);
		}
		return field;
	};

	/**
	 * find object in array by property value
	 * @param    {Array}        arr        array of objects
	 * @param    {String}    propertyName
	 * @param    {String}    value
	 * @param    {Boolean}    onlyIndex
	 * @returns    {Object}
	 */
	this.findObjectInArray = function (arr, propertyName, value, onlyIndex) {
		var found = null;
		edi.utils.each(arr, function (item, index) {
			if (item && item[propertyName] == value) {
				found = onlyIndex ? index : item;
				return true;
			}
		});
		return found;
	};
	/**
	 * Clone object
	 * @param src
	 * @returns {*}
	 */
	this.clone = function (src) {
		return Ext.decode(Ext.encode(src));
	};
	/**
	 * Clone store
	 * @param source
	 * @returns {*}
	 */
	this.deepCloneStore = function (source) {
		var target = Ext.create('Ext.data.Store', {
			model: source.model
		});

		let storeItems = source.getRange();
		let storeProxyItems = source.getProxy()?.data?.items;
		let storeItemsData =
			Array.isArray(storeItems) && storeItems.length > 0
				? storeItems.map((r) => r.data)
				: Array.isArray(storeProxyItems) && storeProxyItems.length > 0
				? storeProxyItems
				: [];

		storeItemsData.forEach(function (itemData) {
			var newRecordData = Ext.clone(itemData);
			var model = new source.model(newRecordData);

			target.add(model);
		});

		return target;
	};
	/**
	 * calculate width of action column
	 * @param length
	 * @returns {number}
	 */
	this.getActionColumnWidth = function (length) {
		return length * edi.constants.ACTION_ICON_WIDTH;
	};
	/**
	 * Get max number value based on it's length (because Ext allowExponential does not work as expected)
	 * @param length        total length including decimals and decimal mark
	 * @param decimals        decimal precision number
	 * @returns {number}
	 */
	this.getMaxValueByLength = function (length, decimals) {
		var res = 0;
		var lengthWithoutDecimals = length;
		if (decimals) {
			lengthWithoutDecimals -= decimals + 1;
		}
		if (lengthWithoutDecimals) {
			res = Math.pow(10, lengthWithoutDecimals) - 1;
		}
		if (decimals) {
			res += (Math.pow(10, decimals) - 1) / Math.pow(10, decimals);
		}
		return res;
	};
	/**
	 * Async function call
	 * @param    {Function}    fn
	 * @param    {Number}      timeout
	 */
	this.async = function (fn, timeout) {
		'function' == typeof fn ? setTimeout(fn, timeout || 0) : null;
	};
	/**
	 * on any form field change set moduleData.isChanged to true
	 * @param    {Object}    form
	 * @param    {Object}    moduleData
	 */
	this.processModuleFormChange = function (form, moduleData) {
		form.on('dirtychange', function (formpanel, isDirty, eOpts) {
			moduleData.isChanged = isDirty;
			var fields = formpanel.getFields(),
				dirty = [];
			fields.each(function (item) {
				if (item.isDirty()) {
					dirty.push(item);
				}
			});
			moduleData.dirtyFields = dirty;
		});
		var fields = form.getForm().getFields();
		fields.each(function (item) {
			item.resetOriginalValue();
		});
	};
	/**
	 * round to
	 * @param    {Number}        value                value as string or float with . or , as delimiter
	 * @param    {Number}        costDecimals        number of signs after comma
	 * @returns    {Number}
	 */
	this.roundTo = function (value, costDecimals) {
		costDecimals = costDecimals ? costDecimals : 0;
		var raw = parseFloat(String(value).replace(',', '.'));
		var multiplier = Math.pow(10, costDecimals);
		return Math.round(raw * multiplier) / multiplier;
	};
	/**
	 * Adds missing zeroes to number, for correct visuals
	 * @param    {Number}    value
	 * @param    {Number}    costDecimals
	 */
	this.displayCurrencyDecimals = function (value, costDecimals) {
		if (Ext.isNumeric(value)) {
			costDecimals = costDecimals ? costDecimals : edi.constants.DEFAULT.SUMM_DISPLAY_PRECISION_NUMBER;
			value = String(this.roundTo(value, costDecimals));
			var length =
				-1 != value.indexOf('.') ? costDecimals - (value.length - (value.indexOf('.') + 1)) : costDecimals;
			if (length == costDecimals && costDecimals > 0) {
				value += '.';
			}
			for (var i = 0; i < length; i++) {
				value += '0';
			}
		}
		return value;
	};
	/**
	 * get url params
	 * @returns    {Object}
	 */
	this.getURLParams = function () {
		return Ext.urlDecode(location.search.substring(1));
	};
	this.genDecimalRegExp = function (totalDigits, fractionDigits) {
		fractionDigits = fractionDigits > 2 ? fractionDigits : 2;
		return new RegExp('^\\d{1,' + (totalDigits - fractionDigits) + '}(\\.\\d{1,' + fractionDigits + '})?$');
	};
	this.regExpDecimal3 = function (total) {
		return edi.utils.genDecimalRegExp(total, 3);
	};
	/**
	 * Generates user password
	 */
	this.passwordGenerator = new (function () {
		var lettersStart = 97;
		var lettersEnd = 122;
		var symbols = '!@#$%';
		var getChar = function (isUppercase) {
			var ch = String.fromCharCode(lettersStart + Math.floor(Math.random() * (lettersEnd - lettersStart + 1)));
			return isUppercase ? ch.toUpperCase() : ch;
		};
		var getNumber = function () {
			return Math.floor(Math.random() * 10);
		};
		var getSymbol = function (ownSymbols) {
			return ownSymbols
				? ownSymbols[Math.floor(Math.random() * ownSymbols.length)]
				: symbols[Math.floor(Math.random() * symbols.length)];
		};
		var shuffleArray = function (array) {
			for (var i = array.length - 1; i > 0; i--) {
				var j = Math.floor(Math.random() * (i + 1));
				var temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
			return array;
		};
		this.generate = function (length, options) {
			var tmp = [getChar(), getChar(true)],
				i,
				x,
				c,
				chX = 0.7,
				chuX = 0.4,
				nX = 0.1;
			length = length ? length : edi.constants.PASSWORD.MIN_LENGTH + 1;
			options = options ? options : {};
			if (options.noNumbers && options.noSymbols) {
				chuX = -1;
				chX = 0.5;
			} else if (options.noNumbers) {
				chuX = 0.1;
				chX = 0.5;
			} else if (options.noSymbols) {
				nX = -1;
			}
			!options.noNumbers ? tmp.push(getNumber()) : null;
			!options.noSymbols ? tmp.push(getSymbol(options.symbols)) : null;

			for (i = 0; tmp.length < length; i++) {
				// NOSONAR
				x = Math.random();
				if (!options.noSymbols) {
					c = getSymbol(options.symbols);
				}
				if (x > chX) {
					c = getChar();
				} else if (x > chuX) {
					c = getChar(true);
				} else if (x > nX && !options.noNumbers) {
					c = getNumber();
				}
				tmp.push(c);
			}
			tmp = shuffleArray(tmp);
			return tmp.join('');
		};
	})();
	/**
	 * compile url string
	 * @param      {String}    url
	 * @param      {Object}    args
	 * @returns    {String}
	 */
	this.compileURL = function (url, args) {
		var res = String(url ? url : '/').split('#')[0];
		args = args ? args : {};
		var isFirstArg = url.indexOf('?') == -1;
		for (var i in args) {
			if (args.hasOwnProperty(i) && '' !== args[i]) {
				if (isFirstArg) {
					res += '?';
				} else {
					res += '&';
				}
				res += encodeURIComponent(i) + '=' + encodeURIComponent(args[i]);
				isFirstArg = false;
			}
		}
		return res;
	};
	/**
	 * format date
	 * @param      {Date/String}       date          date or string.
	 * @param      {String}            [format]        output format
	 * @param      {String}            [srcFormat]     optional - input format. try to parse using new Date if not defined
	 * @returns    {String}
	 */
	this.formatDate = function (date, format, srcFormat) {
		var res = '',
			dateObject;
		srcFormat = srcFormat ? srcFormat : edi.constants.DATE_FORMAT.CLIENT;
		if (date) {
			if (srcFormat && edi.constants.DATE_FORMAT.SERVER != srcFormat && 'string' == typeof date) {
				dateObject = Ext.Date.parse(date, srcFormat);
			} else {
				if ('string' == typeof date && !isNaN(date)) {
					date = parseFloat(date);
				}
				dateObject = new Date(date);
			}
		}
		if (dateObject) {
			format = format ? format : edi.constants.DATE_FORMAT.CLIENT;
			if (format == edi.constants.DATE_FORMAT.SERVER) {
				res = dateObject.getTime();
			} else {
				res = Ext.Date.format(dateObject, format);
			}
		}
		return res;
	};
	/**
	 * Return string with time divided to days, hours, minutes & secs (20days 3hours 8sec)
	 * @param      {Number}      timeMillisecs      Time in milliseconds
	 * @param      {Boolean}     digitalFormat      True to display time in digital format (20:03:33)
	 * @return     {string}                         Time string
	 */
	this.getRemainingTimeString = function (timeMillisecs, digitalFormat) {
		timeMillisecs = timeMillisecs || 0;
		var timeStr = '',
			timeLeft = Math.floor(timeMillisecs / edi.constants.DAY_IN_MS);

		//Get full days
		if (timeMillisecs > edi.constants.DAY_IN_MS) {
			timeMillisecs = timeMillisecs - timeLeft * edi.constants.DAY_IN_MS;
			timeStr += digitalFormat ? timeLeft : edi.i18n.getMessage('remained.time.days', { time: timeLeft });
		}

		//Get full hours
		if (timeMillisecs > edi.constants.HOUR_IN_MS) {
			timeLeft = Math.floor(timeMillisecs / edi.constants.HOUR_IN_MS);
			timeMillisecs = timeMillisecs - timeLeft * edi.constants.HOUR_IN_MS;
			if (digitalFormat) {
				//add : if we have days
				timeStr = timeStr + (timeStr ? ':' : '') + timeLeft;
			} else {
				timeStr = timeStr + ' ' + edi.i18n.getMessage('remained.time.hours', { time: timeLeft });
			}
		}

		if (timeMillisecs > edi.constants.MINUTE_IN_MS) {
			timeLeft = Math.floor(timeMillisecs / edi.constants.MINUTE_IN_MS);
			timeMillisecs = timeMillisecs - timeLeft * edi.constants.MINUTE_IN_MS;

			if (digitalFormat) {
				timeStr = timeStr + (timeStr ? ':' : '') + timeLeft;
			} else {
				timeStr += ' ' + edi.i18n.getMessage('remained.time.minutes', { time: timeLeft });
			}
		}

		timeLeft = Math.floor(timeMillisecs / 1000);
		if (digitalFormat) {
			timeStr = timeStr + (timeStr ? ':' : '00:') + (timeLeft < 10 ? '0' : '') + timeLeft;
		} else {
			timeStr += ' ' + edi.i18n.getMessage('remained.time.seconds', { time: timeLeft });
		}

		return timeStr;
	};
	/**
	 * get data from grid as objects array
	 * @param      {Object}     grid
	 * @returns    {Array}
	 */
	this.getDataFromGrid = function (grid) {
		var result = [];
		if (grid && grid.getTreeValues && typeof grid.getTreeValues === 'function') {
			result = grid.getTreeValues();
		} else if (grid && grid.getStore()) {
			result = edi.utils.getDataFromStore(grid.getStore());
		}
		return result;
	};
	/**
	 * Reads tax calculation method type from org attributes, checks received value validity and sets default value in case of invalid or absence value
	 * @param    {Object}    attributes
	 * @returns {*}
	 */
	this.getTaxCalculationMethod = function (attributes) {
		var deftype = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE,
			i,
			found = false;
		var type = edi.utils.getAttributeByName(attributes, 'taxCalculationMethod');
		if (type) {
			for (i in edi.constants.TAX_CALCULATION_TYPES) {
				if (
					edi.constants.TAX_CALCULATION_TYPES.hasOwnProperty(i) &&
					type == edi.constants.TAX_CALCULATION_TYPES[i]
				) {
					found = true;
					break;
				}
			}
		}
		if (!found) {
			type = deftype;
		}
		return type;
	};
	/**
	 * get data from store as objects array
	 * @param      {Object}     store
	 * @returns    {Array}
	 */
	this.getDataFromStore = function (store) {
		var result = [];
		if (store && 'function' == typeof store.getRange) {
			var range = store.getRange();
			for (var i = 0; i < range.length; i++) {
				result.push(range[i].getData());
			}
		}
		return result;
	};
	/**
	 * Gets country object from countries store
	 * @param iso_2             "RU"
	 * @param iso_3             "RUS"
	 * @param iso_number        "8"
	 * @param iso_number_3     "008"
	 * @returns {{}}
	 */
	this.getCountryFULLByISO = function (iso_2, iso_3, iso_number, iso_number_3, name) {
		var res = {};
		if (
			'function' == typeof edi.stores.initCountryFullStore &&
			(iso_2 || iso_3 || iso_number || iso_number_3 || name)
		) {
			var store = edi.stores.initCountryFullStore(),
				record;
			if (iso_2) {
				record = store.findRecord('iso_2', iso_2, 0, false, false, true);
			} else if (iso_3) {
				record = store.findRecord('iso_3', iso_3, 0, false, false, true);
			} else if (iso_number) {
				record = store.findRecord('iso_number', iso_number, 0, false, false, true);
			} else if (iso_number_3) {
				record = store.findRecord('iso_number_3', iso_number_3, 0, false, false, true);
			} else if (name) {
				record = store.findRecord('name', name, 0, false, false, true);
			}
			if (record) {
				res = Ext.clone(record.getData());
			}
		}
		return res;
	};
	/**
	 * get region by id or by name
	 * @param    {String}    id
	 * @param    {String}    name
	 * @returns    {Object}
	 */
	this.getRegion = function (id, name) {
		var res = {};
		if ('function' == typeof edi.stores.initRegionsStore && (id || name)) {
			var store = edi.stores.initOkvCurrencyStore(),
				record;
			if (id) {
				record = store.findRecord('id', id, 0, false, false, true);
			} else if (name) {
				record = store.findRecord('name', name, 0, false, false, true);
			}
			if (record) {
				res = Ext.clone(record.getData());
			}
		}
		return res;
	};
	/**
	 * Gets currency data object from  OKV store
	 * @param    {Object}    okv    can contain name or charCode or code
	 * @returns    {Object}
	 */
	this.getOkv = function (okv) {
		var res = {};
		if (okv && 'function' == typeof edi.stores.initOkvCurrencyStore) {
			var store = edi.stores.initOkvCurrencyStore(),
				record;
			if (okv.name) {
				record = store.findRecord('name', okv.name, 0, false, false, true);
			}
			if (!record && okv.charCode) {
				record = store.findRecord('charCode', okv.charCode, 0, false, false, true);
			}
			if (!record && okv.code) {
				var propName = Ext.isString(okv.code) ? 'codeStr' : 'code';
				record = store.findRecord(propName, okv.code, 0, false, false, true);
			}
			if (record) {
				res = Ext.clone(record.getData());
			}
		}
		return res;
	};
	/**
	 * get object property by dot separated path
	 * @param      {Object}     obj
	 * @param      {String}     propPath        dot separated path "prop1.prop2"
	 * @param      {Boolean}    [asArray]         return value as array
	 * @returns    {String/Array}               "" (or [] if asArray) if none found
	 */
	this.getObjectProperty = function (obj, propPath, asArray) {
		var res = '';
		obj = obj && 'object' == typeof obj ? obj : {};

		var path = String(propPath).split('.');
		var tmp = obj;

		for (var i = 0; i < path.length; i++) {
			var propName = path[i];
			tmp = tmp[propName];
			if (undefined === tmp || null === tmp) {
				if (asArray && !res) {
					res = [];
				}
				break;
			} else if (i == path.length - 1) {
				res = tmp;
				if (asArray) {
					if (res) {
						if (undefined === res.length) {
							res = [res];
						}
					} else {
						res = [];
					}
				}
			}
		}
		return res;
	};
	/**
	 * get object property by dot separated path
	 * @param      {Object}     obj
	 * @param      {String}     path        dot separated path "prop1.prop2" or path with condition "prop1[prop3=something].prop2"  or  "prop1[prop3=something]"
	 * @returns    {String|Object|Array}    "" if none found
	 */
	this.getObjectPropertyEx = function (obj, path) {
		if (typeof path !== 'string') {
			edi.core.logMessage('getObjectPropertyEx param type error', 'error');
			return '';
		}
		var object = Ext.clone(obj);
		var array = path.split(/(\[|\])/);
		for (var i = 0; i < array.length; i++) {
			if (array[i] != '[' && array[i] != ']') {
				object = edi.utils.getObjectProperty(object, array[i]);
			} else {
				if (array[i] != ']') {
					var conditionArray = array[++i].split('=='),
						conditionPath = conditionArray[0],
						conditionValue = conditionArray[1];
					if (!Array.isArray(object)) {
						object = [object];
					}
					object = object.find(function (item) {
						var conditionPathValue = edi.utils.getObjectProperty(item, conditionPath);
						return conditionPathValue == conditionValue;
					});
					if (object && array[i + 1] == ']' && array[i + 2] == '') {
						return object;
					}
				}
			}
		}
		return object;
	};
	/**
	 * @param ob Object                 The object to flatten
	 * @param prefix String (Optional)  The prefix to add before each key, also used for recursion
	 **/
	this.flattenObject = function (ob, prefix = false, result = null) {
		result = result || {};

		// Preserve empty objects and arrays, they are lost otherwise
		if (prefix && typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
			result[prefix] = Array.isArray(ob) ? [] : {};
			return result;
		}

		prefix = prefix ? prefix + '.' : '';

		for (const i in ob) {
			if (Object.prototype.hasOwnProperty.call(ob, i)) {
				// Only recurse on true objects and arrays, ignore custom classes like dates
				if (
					typeof ob[i] === 'object' &&
					(Array.isArray(ob[i]) || Object.prototype.toString.call(ob[i]) === '[object Object]') &&
					ob[i] !== null
				) {
					// Recursion on deeper objects
					this.flattenObject(ob[i], prefix + i, result);
				} else {
					result[prefix + i] = ob[i];
				}
			}
		}
		return result;
	};

	this.unflattenObject = function (ob) {
		const result = {};
		for (const i in ob) {
			if (Object.prototype.hasOwnProperty.call(ob, i)) {
				const keys = i.match(/(?:^\.+)?(?:\.{2,}|[^.])+(?:\.+$)?/g); // Just a complicated regex to only match a single dot in the middle of the string
				keys.reduce((r, e, j) => {
					return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? ob[i] : {}) : []);
				}, result);
			}
		}
		return result;
	};
	/**
	 * delete object property by dot separated path
	 * @param      {Object}     obj
	 * @param      {String}     propPath        dot separated path "prop1.prop2" or path with condition "prop1[prop3=something].prop2"
	 * @returns    {Object}
	 */
	this.deleteObjectProperty = function (obj, propPath) {
		obj = obj && 'object' == typeof obj ? obj : {};

		var path = String(propPath).split('.');
		var tmp = obj;

		for (var i = 0; i < path.length; i++) {
			var propName = path[i],
				val = tmp[propName];
			if (undefined === val || null === val) {
				tmp[propName] = {};
			}
			if (i == path.length - 1) {
				delete tmp[propName];
			} else if (typeof tmp[propName] != 'object') {
				tmp[propName] = {};
			}
			tmp = tmp[propName];
		}
		return obj;
	};

	/**
	 * delete object property by dot separated path
	 * @param      {Object}     obj
	 * @param      {String}     path    dot separated path "prop1.prop2" or path with condition "prop1[prop3=something].prop2"
	 * @returns    {Object}
	 */
	this.deleteObjectPropertyEx = function (obj, path) {
		var array = path.split(/(\[|\])/);
		var objCursor = obj;

		for (var i = 0; i < array.length; i++) {
			var pathCursor = array[i];
			if (pathCursor != '[' && pathCursor != ']') {
				if (i == array.length - 1) {
					if ('' !== pathCursor) {
						edi.utils.deleteObjectProperty(objCursor, pathCursor);
					}
				} else {
					var tmp = edi.utils.getObjectProperty(objCursor, pathCursor);
					if (Array.isArray(tmp) || Ext.isObject(tmp)) {
						objCursor = tmp;
					} else {
						edi.utils.deleteObjectProperty(objCursor, pathCursor);
						objCursor = edi.utils.getObjectProperty(objCursor, pathCursor);
					}
				}
			} else {
				if (pathCursor != ']') {
					pathCursor = array[++i];
					var conditionArray = pathCursor.split('=='),
						conditionPath = conditionArray[0],
						conditionValue = conditionArray[1];
					if (Array.isArray(objCursor)) {
						var indexPath = -1;
						var finded = tmp.find(function (item, index) {
							var conditionPathValue = edi.utils.getObjectProperty(item, conditionPath);
							if (conditionPathValue == conditionValue) {
								indexPath = index;
							}
							return conditionPathValue == conditionValue;
						});
						if (!finded) {
							return obj;
						}

						if (array[i + 2] === '') {
							objCursor.splice(indexPath, 1);
						} else {
							objCursor = objCursor[indexPath];
						}
					} else {
						edi.utils.deleteObjectProperty(objCursor, conditionPath);
						objCursor = edi.utils.getObjectProperty(objCursor, conditionPath);
					}
				}
			}
		}
		return obj;
	};

	/**
	 * set object property by dot separated path
	 * @param      {Object}     obj
	 * @param      {String}     path    dot separated path "prop1.prop2" or path with condition "prop1[prop3=something].prop2"  or  "prop1[prop3=something]"
	 * @param      {*}          value       value to set
	 * @returns    {Object}
	 */
	this.setObjectPropertyEx = function (obj, path, value) {
		var array = path.split(/(\[|\])/);
		var objCursor = obj;
		for (var i = 0; i < array.length; i++) {
			var pathCursor = array[i];
			if (pathCursor != '[' && pathCursor != ']') {
				if (i == array.length - 1) {
					edi.utils.setObjectProperty(objCursor, pathCursor, value);
				} else {
					var tmp = edi.utils.getObjectProperty(objCursor, pathCursor);
					if (Array.isArray(tmp) || Ext.isObject(tmp)) {
						objCursor = tmp;
					} else {
						edi.utils.setObjectProperty(objCursor, pathCursor, []);
						objCursor = edi.utils.getObjectProperty(objCursor, pathCursor);
					}
				}
			} else {
				if (pathCursor != ']') {
					pathCursor = array[++i];
					var conditionArray = pathCursor.split('=='),
						conditionPath = conditionArray[0],
						conditionValue = conditionArray[1];
					if (Array.isArray(objCursor)) {
						var indexPath = -1;
						var finded = tmp.find(function (item, index) {
							var conditionPathValue = edi.utils.getObjectProperty(item, conditionPath);
							if (conditionPathValue == conditionValue) {
								indexPath = index;
							}
							return conditionPathValue == conditionValue;
						});

						if (array[i + 1] == ']' && array[i + 2] == '') {
							if (finded && indexPath >= 0) {
								objCursor[indexPath] = value;
							} else {
								objCursor.push(value);
							}
							return obj;
						} else {
							if (finded) {
								objCursor = finded;
							} else {
								objCursor.push(edi.utils.setObjectProperty({}, conditionPath, conditionValue));
								objCursor = objCursor[objCursor.length - 1];
							}
						}
					} else {
						edi.utils.setObjectProperty(objCursor, conditionPath, conditionValue);
						objCursor = edi.utils.getObjectProperty(objCursor, conditionPath);
					}
				}
			}
		}
		return obj;
	};
	/**
	 * set object property by dot separated path
	 * @param      {Object}     obj
	 * @param      {String}     propPath    dot separated path "prop1.prop2"
	 * @param      {*}          value       value to set
	 * @returns    {Object}
	 */
	this.setObjectProperty = function (obj, propPath, value) {
		obj = obj && 'object' == typeof obj ? obj : {};

		var path = String(propPath).split('.');
		var tmp = obj;

		for (var i = 0; i < path.length; i++) {
			var propName = path[i];
			var val = tmp[propName];
			if (undefined === val || null === val) {
				tmp[propName] = {};
			}
			if (i == path.length - 1) {
				tmp[propName] = value;
			} else if (typeof tmp[propName] != 'object') {
				tmp[propName] = {};
			}
			tmp = tmp[propName];
		}
		return obj;
	};
	var additionalData = null; // userData.user.additionalData
	/**
	 * Get user additional data object
	 * @returns {*}
	 */
	var getAdditionalData = function () {
		let data = Ext.clone(edi.core.getExtraData('user.additionalData')) || '{}';
		additionalData = Ext.decode(data);
		return additionalData;
	};

	/**
	 * Format column config name (replacement symbol from "-" to "_")
	 * @param    {Object}    data    user additional data array
	 * @param    {String}    name    name of the column
	 * @returns {String|Array}
	 */
	var formatColumnAdditionalData = function (data, name) {
		if (name.substr(0, 2) == 'c.') {
			var columns = edi.utils.getObjectProperty(additionalData, 'c');
			for (var columnsName in columns) {
				if (columns.hasOwnProperty(columnsName) && columnsName.indexOf('-') >= 0) {
					data.c[columnsName.split('-').join('_')] = Ext.clone(columns[columnsName]);
					delete data.c[columnsName];
				}
			}
		}
	};
	/**
	 * Get user additional data
	 * @param name        dot separated path "prop1.prop2"
	 * @returns {String|Array}
	 */
	this.getData = function (name) {
		var additionalData = getAdditionalData();
		formatColumnAdditionalData(additionalData, name);
		return edi.utils.getObjectProperty(additionalData, name);
	};
	/**
	 * Set user additional data
	 * @param name        dot separated path "prop1.prop2"
	 * @param value
	 */
	this.setData = function (name, value, callback) {
		var additionalData = getAdditionalData();
		formatColumnAdditionalData(additionalData, name);
		edi.utils.setObjectProperty(additionalData, name, String(value));
		edi.core.setExtraData('user.additionalData', Ext.encode(additionalData), callback);
	};
	/**
	 * get organizaion data
	 * @param    {Object}      obj                contains orgId or orgILN or orgINN
	 * @param    {Function}    [callback]
	 */
	this.getOrg = function (obj, callback) {
		var byOrgId = !!obj.orgId;
		var byOrgILN = !!obj.orgILN;
		var byOrgINN = !!obj.orgINN;
		var byINNAndILN = obj.byINNAndILN;
		var relations = edi.relations.getRelationsWithForeign({
			self: true
		});
		var org = null;
		for (var i = 0; i < relations.length; i++) {
			if (byOrgILN && byOrgINN) {
				if (
					byINNAndILN
						? obj.orgILN == relations[i].iln && obj.orgINN == relations[i].inn
						: obj.orgILN == relations[i].iln || obj.orgINN == relations[i].inn
				) {
					org = relations[i];
					break;
				}
			} else if (
				(byOrgId && obj.orgId == relations[i].id) ||
				(byOrgILN && obj.orgILN == relations[i].iln) ||
				(byOrgINN && obj.orgINN == relations[i].inn)
			) {
				org = relations[i];
				break;
			}
		}
		'function' == typeof callback ? callback(org) : null;
		return org;
	};
	/**
	 * load one record from catalog by it's id (EAN/ILN)
	 * @param    {Object}    fromOrg        catalog creator org
	 * @param    {Object}    toOrg        catalog receiver org
	 * @param    {String}    id            id (EAN/ILN)
	 * @param    {String}    catalog        catalog name from edi.constants.CATALOGS
	 * @param    {Function}    callback
	 *
	 */
	this.loadCatalogRecord = function (fromOrg, toOrg, id, catalog, callback) {
		var url = null;
		if (!toOrg) {
			toOrg = fromOrg;
		}
		if (catalog == edi.constants.CATALOGS.LOCCAT) {
			url = edi.rest.services.CATALOGS.LOCCAT.GET;
		} else if (catalog == edi.constants.CATALOGS.DELCAT) {
			url = edi.rest.services.CATALOGS.DELCAT.GET;
		} else if (catalog == edi.constants.CATALOGS.PRODCAT) {
			url = edi.rest.services.CATALOGS.PRODCAT.GET;
		}
		if (url) {
			var failure = function () {
				callback ? callback(null) : null;
			};
			edi.rest.sendRequest(
				edi.utils.formatString(
					url,
					{
						id: id,
						fromOrgId: fromOrg.id,
						toOrgId: toOrg.id
					},
					true
				),
				'GET',
				null,
				function (data) {
					if (data.data) {
						callback ? callback(data.data) : null;
					} else {
						failure();
					}
				},
				failure
			);
		} else {
			callback ? callback(null) : null;
		}
	};
	/**
	 * Check is valid EAN
	 * @param    {String}    ean
	 * @return    {Boolean}
	 */
	this.isValidEAN = function (ean) {
		return String(ean).match(edi.constants.VALIDATORS.EAN);
	};
	/**
	 * loads multiple record from product catalog by it's id (EAN/ILN)
	 * @param    {Object}    fromOrg        catalog creator org
	 * @param    {Object}    toOrg        catalog receiver org
	 * @param    {Array}        ids            id's array (EAN)
	 * @param    {Function}    callback    with array as argument
	 *
	 */
	this.loadProductsFromCatalog = function (fromOrg, toOrg, ids, callback) {
		var validIds = [];
		if (ids && ids.length) {
			for (var i = 0; i < ids.length; i++) {
				if (edi.utils.isValidEAN(ids[i])) {
					validIds.push(ids[i]);
				}
			}
		}
		if (fromOrg && toOrg && validIds.length) {
			var url = edi.rest.services.CATALOGS.PRODCAT.BATCH.GET;
			var failure = function () {
				callback ? callback([]) : null;
			};
			edi.rest.sendRequest(
				edi.utils.formatString(
					url,
					{
						ids: validIds.join(','),
						fromOrgId: fromOrg.id,
						toOrgId: toOrg.id
					},
					true
				),
				'GET',
				null,
				function (data) {
					if (data.items && data.items.length) {
						callback ? callback(data.items) : null;
					} else {
						failure();
					}
				},
				failure,
				undefined,
				{
					suppressDefaultError: true
				}
			);
		} else {
			callback ? callback([]) : null;
		}
	};
	/**
	 * load partie from defined catalog
	 * @param    {Object}    data        document object
	 * @param    {String}    rootPath    dot separated path to partie object in document
	 * @param    {String}    idPath        dot separated path to partie id in document
	 * @param    {Object}    fromOrg        catalog creator org
	 * @param    {Object}    toOrg        catalog receiver org
	 * @param    {String}    catalog        catalog name from edi.constants.CATALOGS
	 * @param    {Function}    converter    converter method for convertation org into target format
	 * @param    {Function}    callback    callback with updated document will be fired
	 */
	this.loadPartieFromCatalog = function (data, rootPath, idPath, fromOrg, toOrg, catalog, converter, callback) {
		var id = edi.utils.getObjectProperty(data, idPath);
		var partie = edi.utils.getObjectProperty(data, rootPath);
		if (id && fromOrg) {
			edi.utils.loadCatalogRecord(fromOrg, toOrg, id, catalog, function (org) {
				if (org) {
					org = edi.converters.convertCatToOrg(org);
					var converted = converter ? converter(org) : org;
					edi.utils.clearEmptyValues(partie);
					Ext.applyIf(partie, converted);
				}
				callback ? callback(data, !!org) : null;
			});
		} else {
			callback ? callback(data, false) : null;
		}
	};
	/**
	 * load document partie and set it into document object
	 * @param    {Object}    data        document object
	 * @param    {String}    rootPath    dot separated path to partie object in document
	 * @param    {String}    idPath        dot separated path to partie id in document
	 * @param    {String}    idType        type of partie id iln/inn default is iln
	 * @param    {Function}    converter    converter method for convertation org into target format
	 * @param    {Function}    callback    callback with updated document will be fired
	 */
	this.loadPartie = function (data, rootPath, idPath, idType, converter, callback) {
		var id = edi.utils.getObjectProperty(data, idPath);
		var partie = edi.utils.getObjectProperty(data, rootPath);
		if (id) {
			var args = {};
			if (idType === 'inn') {
				args.orgINN = id;
			} else {
				args.orgILN = id;
			}
			edi.utils.getOrg(args, function (org) {
				if (org) {
					var converted = converter ? converter(org) : org;
					edi.utils.clearEmptyValues(partie);
					edi.utils.clearEmptyValues(converted);
					Ext.applyIf(partie, converted);
				}
				callback ? callback(data, !!org) : null;
			});
		} else {
			callback ? callback(data, false) : null;
		}
	};
	this.getOrgByILN = function (iln, callback, converter) {
		edi.utils.getOrg(
			{
				orgILN: iln
			},
			function (org) {
				if (org) {
					org = converter ? converter(org) : org;
				}
				callback ? callback(org) : null;
			}
		);
	};
	this.getOrgByINN = function (inn, callback, converter) {
		edi.utils.getOrg(
			{
				orgINN: inn
			},
			function (org) {
				if (org) {
					org = converter ? converter(org) : org;
				}
				callback ? callback(org) : null;
			}
		);
	};
	/**
	 * parse all object fields to string
	 * @param    {Object}    obj
	 * @returns    {Object}
	 */
	this.stringifyObjectFields = function (obj) {
		obj = obj ? obj : {};
		var res = {};
		for (var i in obj) {
			if (obj.hasOwnProperty(i)) {
				if (obj[i] && 'object' == typeof obj[i]) {
					res[i] = __self.stringifyObjectFields(obj[i]);
				} else if (obj[i] != undefined) {
					res[i] = String(obj[i]);
				}
			}
		}
		return res;
	};
	/**
	 * fires fn for each element in array/object with args (value, index)
	 * @param  {Object}    obj     object/array
	 * @param  {Function}  fn      will be called for each element of array or property of object with args (value, index). if fn returns true - break happens
	 */
	this.each = function (obj, fn) {
		var i;
		if (obj && 'object' == typeof obj) {
			var doBreak = false;
			if (obj.length) {
				for (i = 0; i < obj.length; i++) {
					fn ? (doBreak = fn(obj[i], i)) : null;
					if (doBreak) {
						break;
					}
				}
			} else {
				for (i in obj) {
					if (obj.hasOwnProperty(i)) {
						fn ? (doBreak = fn(obj[i], i)) : null;
						if (doBreak) {
							break;
						}
					}
				}
			}
		}
	};
	/**
	 * formats string with values
	 * @param      {String}     str         string with markers for replacement "Hello {0}, nice to {action} you!"
	 * @param      {Object}     values      array or object with values.
	 * @param      {Boolean}    [dontClear]   don't clear all {key} markers.
	 * @returns    {String}
	 */
	this.formatString = function (str, values, dontClear) {
		var res = String(str),
			i;
		if (values && 'object' == typeof values) {
			if (values.length) {
				for (i = 0; i < values.length; i++) {
					res = res.split('{' + i + '}').join(values[i]);
				}
			} else {
				for (i in values) {
					if (values.hasOwnProperty(i)) {
						res = res.split('{' + i + '}').join(values[i] === undefined ? '' : values[i]);
					}
				}
			}
		}
		if (!dontClear) {
			res = res.replace(/[{][\w\.\-]+[}]/g, '');
		}
		return res;
	};
	/**
	 * method round number for Gauss
	 * @param      {Number}     num         Number to round
	 * @returns    {Number}
	 */
	this.gaussRound = function (num) {
		var n = +num.toFixed(8),
			i = Math.floor(n),
			f = n - i,
			e = 1e-8;
		return f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
	};
	/**
	 * collect form values and build object based on field names
	 * @param      {Object}     form
	 * @param      {String}     [path]      optional - dot separated path "prop1.prop2"
	 * @param       {Boolean}    [useModelData]      is use the getModelData method to retrieve values from fields
	 * @returns    {Object}
	 */
	this.collectFormValues = function (form, path, useModelData) {
		var res = {};
		if (form) {
			var values = form.getValues(false, false, false, useModelData);
			for (var i in values) {
				if (values.hasOwnProperty(i) && (!path || String(i).indexOf(path) == 0)) {
					var propertyPath = String(i);
					if (path) {
						propertyPath = propertyPath.replace(path, '');
					}
					if (propertyPath) {
						if (propertyPath[0] == '.') {
							propertyPath = propertyPath.substring(1);
						}
						var parhArr = propertyPath.split('.');
						var pathLink = res;
						for (var j = 0; j < parhArr.length; j++) {
							var propName = parhArr[j];
							if (j == parhArr.length - 1) {
								pathLink[propName] = values[i];
							} else {
								if (!pathLink[propName]) {
									pathLink[propName] = {};
								}
								pathLink = pathLink[propName];
							}
						}
					} else {
						res = values[i];
						break;
					}
				}
			}
		}
		return res;
	};

	/**
	 * collect form fields and build object based on field names
	 * @param      {Object}     form
	 * @param      {String}     path      optional - dot separated path "prop1.prop2"
	 * @returns    {Object}
	 */
	this.getFormFields = function (form, path) {
		var res = {};
		if (form) {
			var fields = [];
			if (form.getForm) {
				fields = form.getForm().monitor.getItems();
			}
			if (fields && fields.items) {
				fields = fields.items;
			}
			if (fields) {
				for (var i = 0; i < fields.length; i++) {
					var field = fields[i];
					if (!path || field.name.indexOf(path) == 0) {
						res[field.name] = field;
					}
				}
			}
		}
		return res;
	};

	this.multiplyString = function (str, amount) {
		var res = '';
		amount = amount ? amount : 0;
		for (var i = 0; i < amount; i++) {
			res += str;
		}
		return res;
	};

	/**
	 * clear empty values from object with recursion
	 * @param        {Object}        obj
	 * @param        {Boolean}        [removeZeros]
	 * @returns        {Boolean}        isEmpty
	 */
	this.clearEmptyValues = function (obj, removeZeros) {
		var isEmpty = true;
		if (null !== obj && undefined !== obj && '' !== obj && (!removeZeros || 0 !== obj)) {
			if ('object' == typeof obj) {
				for (var i in obj) {
					if (obj.hasOwnProperty(i) && __self.clearEmptyValues(obj[i], removeZeros)) {
						delete obj[i];
					} else {
						isEmpty = false;
					}
				}
			} else {
				isEmpty = false;
			}
		}
		return isEmpty;
	};
	/**
	 * clear empty values from array
	 * @param        {Object}        arr
	 */
	this.clearEmptyArrayValues = function (arr) {
		if (arr && arr.length) {
			var index = arr.length - 1;
			for (var i = index; i >= 0; i--) {
				if (null === arr[i] || undefined === arr[i]) {
					arr.splice(i, 1);
				}
			}
		}
	};

	/**
	 * Returns certificate field name by map of OIDs
	 * @param	{String}	name
	 * @return	{String|null}	new name of field or null
	 */
	this.findCertKeyInMap = function (name) {
		let newName = null;
		switch (name) {
			case 'O':
				newName = 'organization';
				break;
			case 'OU':
				newName = 'organizationUnit';
				break;
			case 'CN':
				newName = 'commonName';
				break;
			case 'SN':
			case 'SURNAME':
				newName = 'lastname';
				break;
			case 'G':
			case 'GN':
			case 'GIVENNAME':
				newName = 'givenname';
				break;
			case 'T':
				newName = 'position';
				break;
			case 'C':
				newName = 'countryCode';
				break;
			case 'E':
				newName = 'email';
				break;
			case 'S':
			case 'ST':
				newName = 'state';
				break;
			case 'L':
				newName = 'location';
				break;
			case 'STREET':
				newName = 'street';
				break;
			case 'OID.1.2.643.100.1':
			case '1.2.643.100.1':
			case 'OGRN':
			case 'ОГРН':
				newName = 'ogrn';
				break;
			case 'SNILS':
			case 'СНИЛС':
				newName = 'snils';
				break;
			case '1.2.643.3.131.1.1':
			case 'INN':
			case 'ИНН':
				newName = 'inn';
				break;
			case 'OID.1.2.643.100.4':
			case '1.2.643.100.4':
			case 'INNLE':
			case 'ИННЮЛ':
			case 'ИНН ЮЛ':
				newName = 'innle';
				break;
			case 'OID.1.2.643.100.5':
			case '1.2.643.100.5':
			case 'OGRNIP':
			case 'ОГРНИП':
			case 'ОГРН ИП':
				newName = 'ogrnip';
				break;
		}
		return newName;
	};

	/**
	 * Returns clean value string
	 * @param	{String}	value
	 * @return	{String}	clean value
	 */
	this.cleanCertValue = function (value = '') {
		let cleanValue = value.replace(/\\,/g, ',');
		cleanValue = cleanValue.replace(/\\\\,/g, ',');
		cleanValue = cleanValue.replace(/\\,/g, ',');
		cleanValue = cleanValue.replace(/\\"/g, '"');
		return cleanValue;
	};

	/**
	 * Parses certificate string and return object containing parsed property names with values
	 * @param	{String}	str		full of certificates subject string
	 * @param	{Object}	[options]
	 * @returns	{Object}
	 *
	 * Examples of string for parsing
	 * "CN=Ипешников Петр Тарасович, SN=Ипешников, G=Петр Тарасович, C=RU, S=78 г. Санкт-Петербург, L=Санкт-Петербург, STREET=\"ул. Смоленская, д.12\", SNILS=02501578932, INN=784561233214, E=fl@mail.ru, OGRNIP=876541233214567"
	 */
	this.fullStringCertParse = function (str, options) {
		let obj = {};
		let bufN = '';
		let bufV = '';
		let mode = 'val';
		let txt = str;
		const flush = (name, value) => (obj[name.trim()] = value.trim());
		//Пройдемся по строке с конца в начало посимвольно. Будем добавлять текущий символ или в буфер ключа или значения
		//в зависимости от текущего режима. Режим меняется каждый раз когда натыкаемся на символ разделитель "=" и ",".
		//Парсинг начинаем всегда в режиме "значение" т.к. срока всегда оканчивается каким-то значением. Будем наполнять
		//буфер значения пока не дойдем до "=", переключим режим на "ключ" и начнем наполнять буфер ключа, пока
		//не дойдем до разделителя блоков ",". Сохраняем значения в объект (очищая от пробелов между разделителями блоков
		//ключ-значение), сбрасываем буферы, переключаемся в режим поиска значения и продолжаем так,
		//пока не доберемся до конца строки. После перебора всей строки сохраним последнюю пару ключ-значение в объект.
		for (let i = txt.length; i--; i > 0) {
			let sym = txt[i];
			txt = txt.slice(0, -1);
			if (mode === 'name') {
				if (sym === ',') {
					flush(bufN, bufV);
					bufN = bufV = '';
					mode = 'val';
				} else {
					bufN = sym + bufN;
				}
			} else {
				if (sym === '=') {
					mode = 'name';
				} else {
					bufV = sym + bufV;
				}
			}
		}
		flush(bufN, bufV);

		//Сформируем конечный объект, подменяя ключи по мапе OIDов
		//несуществующие в мапе ключи по умолчанию не добавляются.
		let certParsedData = {};
		Object.entries(obj).forEach(([name, value]) => {
			const newName = edi.utils.findCertKeyInMap(name);
			const cleanValue = edi.utils.cleanCertValue(value);

			if (newName) {
				if (newName === 'givenname') {
					let givenname = cleanValue.split(' ');
					certParsedData['firstname'] = givenname.shift();
					if (givenname.length) {
						certParsedData['middlename'] = givenname.join(' ');
					}
				} else {
					certParsedData[newName] = cleanValue;
				}
			} else if (options?.returnAllValues === true) {
				certParsedData[name] = cleanValue;
			}
		});

		return certParsedData;
	};
	/**
	 * Convert certificate subject or issuer string into Object
	 * @param	{String}	value
	 * @param	{Object}	[options]
	 * @returns	{Object}
	 */
	this.certificateObject = function (value, options) {
		return edi.utils.fullStringCertParse(value, options);
	};
	/**
	 * Parses certificate data to human readable form
	 * @param    {Object/Array}    cert    certificate or collection of certificates
	 */
	this.parseCertificateData = function (cert) {
		var retData, data, i;
		if (cert) {
			if (cert.length) {
				retData = [];
				for (i = 0; i < cert.length; i++) {
					if (cert[i].cert) {
						data = this.parseCertificateData(cert[i].cert);
						data.availableDocTypesForSignature = cert[i].availableDocTypesForSignature;
					} else {
						data = this.parseCertificateData(cert[i]);
					}
					data ? retData.push(data) : null;
				}
			} else {
				var today = new Date().getTime();
				var validFrom = new Date(cert.ValidFromDate).getTime();
				var validTo = new Date(cert.ValidToDate).getTime();
				var algorithm = 'undefined' != typeof cert.PublicKey ? cert.PublicKey().Algorithm : {};
				if (!validFrom || !validTo || (today >= validFrom && today <= validTo)) {
					retData = {
						subject: {},
						issuer: {},
						isValid: true,
						serial: cert.SerialNumber,
						dateFrom: cert.ValidFromDate,
						dateFromParsed: validFrom,
						dateTo: cert.ValidToDate,
						dateToParsed: validTo,
						version: cert.Version,
						hash: cert.Thumbprint,
						algorithm: {
							name: algorithm.FriendlyName,
							oid: algorithm.Value
						},
						cert: cert
					};
					retData.subject = this.fullStringCertParse(cert.SubjectName);
					retData.issuer = this.fullStringCertParse(cert.IssuerName);
				}
			}
		}
		return retData;
	};
	/**
	 * Convert name "exampleFieldName" to "example.field.name"
	 * @param    {String}    name
	 * @returns    {String}
	 */
	this.formatName = function (name) {
		var res = String(name);
		res = res.split('_').join('.');
		res = res.replace(/[A-Z]/g, function (found, index) {
			return (index > 0 && res[index - 1] == res[index - 1].toLowerCase() ? '.' : '') + found;
		});
		res = res.split('..').join('.');
		res = res.split('-.').join('.');
		return res.toLowerCase();
	};
	/**
	 * simple xml parser
	 */
	this.XML = {
		/**
		 * stringify object to XML
		 * @param      {Object}        obj
		 * @param      {Boolean}    formatted      should result be formatted? false by default
		 * @returns    {String}
		 */
		stringify: function (obj, formatted) {
			obj = obj ? obj : {};
			__self.clearEmptyValues(obj);
			var stringifyLevel = function (tagName, data, level) {
				var res = '',
					i;

				if (data && 'object' == typeof data) {
					var val = '';
					if (undefined !== data.length) {
						for (i = 0; i < data.length; i++) {
							val += stringifyLevel(tagName, data[i], level);
						}
						res = val;
					} else {
						for (i in data) {
							if (data.hasOwnProperty(i)) {
								val += stringifyLevel(i, data[i], level + (tagName ? 1 : 0));
							}
						}
						if (tagName) {
							res = __self.formatString('{t}<{tag}>{n}{val}{t}</{tag}>{n}', {
								n: formatted ? '\n' : '',
								t: formatted ? __self.multiplyString('\t', level) : '',
								tag: tagName,
								val: val
							});
						} else {
							res = val;
						}
					}
				} else {
					if (tagName) {
						res = __self.formatString('{t}<{tag}>{val}</{tag}>{n}', {
							n: formatted ? '\n' : '',
							t: formatted ? __self.multiplyString('\t', level) : '',
							tag: tagName,
							val: data
						});
					} else {
						res = String(data);
					}
				}

				return res;
			};
			return __self.formatString('<?xml version="1.0" encoding="UTF-8"?>{n}{data}', {
				n: formatted ? '\n' : '',
				data: stringifyLevel(null, obj, 0)
			});
		}
	};

	/**
	 * base64 encode/decode
	 */
	this.base64 = new (function () {
		var _base64 = this;
		// private property
		var _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

		// public method for encoding
		this.encode = function (input) {
			var output = '';
			var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
			var i = 0;

			input = _base64._utf8_encode(String(input ? input : ''));

			while (i < input.length) {
				chr1 = input.charCodeAt(i++);
				chr2 = input.charCodeAt(i++);
				chr3 = input.charCodeAt(i++);

				enc1 = chr1 >> 2;
				enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
				enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
				enc4 = chr3 & 63;

				if (isNaN(chr2)) {
					enc3 = enc4 = 64;
				} else if (isNaN(chr3)) {
					enc4 = 64;
				}
				output =
					output + _keyStr.charAt(enc1) + _keyStr.charAt(enc2) + _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
			}

			return output;
		};

		// public method for decoding
		this.decode = function (input) {
			var output = '';
			var chr1, chr2, chr3;
			var enc1, enc2, enc3, enc4;
			var i = 0;

			input = String(input ? input : '').replace(/[^A-Za-z0-9\+\/\=]/g, '');

			while (i < input.length) {
				enc1 = _keyStr.indexOf(input.charAt(i++));
				enc2 = _keyStr.indexOf(input.charAt(i++));
				enc3 = _keyStr.indexOf(input.charAt(i++));
				enc4 = _keyStr.indexOf(input.charAt(i++));

				chr1 = (enc1 << 2) | (enc2 >> 4);
				chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
				chr3 = ((enc3 & 3) << 6) | enc4;

				output = output + String.fromCharCode(chr1);

				if (enc3 != 64) {
					output = output + String.fromCharCode(chr2);
				}
				if (enc4 != 64) {
					output = output + String.fromCharCode(chr3);
				}
			}

			output = _base64._utf8_decode(output);
			return output;
		};

		// private method for UTF-8 encoding
		this._utf8_encode = function (string) {
			string = string.replace(/\r\n/g, '\n');
			var utftext = '',
				c;

			for (var n = 0; n < string.length; n++) {
				c = string.charCodeAt(n);

				if (c < 128) {
					utftext += String.fromCharCode(c);
				} else if (c > 127 && c < 2048) {
					utftext += String.fromCharCode((c >> 6) | 192);
					utftext += String.fromCharCode((c & 63) | 128);
				} else {
					utftext += String.fromCharCode((c >> 12) | 224);
					utftext += String.fromCharCode(((c >> 6) & 63) | 128);
					utftext += String.fromCharCode((c & 63) | 128);
				}
			}

			return utftext;
		};

		// private method for UTF-8 decoding
		this._utf8_decode = function (utftext) {
			var string = '';
			var i = 0;
			var c, c2, c3;

			while (i < utftext.length) {
				c = utftext.charCodeAt(i);

				if (c < 128) {
					string += String.fromCharCode(c);
					i++;
				} else if (c > 191 && c < 224) {
					c2 = utftext.charCodeAt(i + 1);
					string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
					i += 2;
				} else {
					c2 = utftext.charCodeAt(i + 1);
					c3 = utftext.charCodeAt(i + 2);
					string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
					i += 3;
				}
			}

			return string;
		};
	})();
	/**
	 * Get closest value
	 * @param    {Number}    value
	 * @param    {Array}        values
	 * @return    {Number}
	 */
	this.getClosestValue = function (value, values) {
		var dif, index, i, val, d;
		for (i = 0; i < values.length; i++) {
			val = values[i];
			d = Math.abs(val - value);
			if ('undefined' == typeof dif || d < dif) {
				dif = d;
				index = i;
			}
		}
		return values[index];
	};
	/**
	 * Calculate tax rate
	 * @param    {Number}    totalWithVat
	 * @param    {Number}    totalWithoutVat
	 * @return   {*}
	 */
	this.calculateTaxRate = function (totalWithVat, totalWithoutVat) {
		var value = 0;
		totalWithVat = parseFloat(totalWithVat) || 0;
		totalWithoutVat = parseFloat(totalWithoutVat) || 0;
		if (totalWithVat && totalWithoutVat) {
			value = ((totalWithVat - totalWithoutVat) / totalWithoutVat) * 100;
		}
		var userData = edi.core.getUserData();
		var rates = edi.methods.taxRates.getRatesByCountry(userData.org.country);
		var values = rates.map(function (rate) {
			return edi.methods.calculateTaxRate(rate.id);
		});
		value = edi.utils.getClosestValue(value, values);

		return value;
	};
	/**
	 * Returns collection of all cookies valid for current pages. Multiple cookies with same name will have collection of values.
	 */
	this.getCookies = function () {
		var c = document.cookie,
			v = 0,
			cookies = {};
		if (document.cookie.match(/^\s*\$Version=(?:"1"|1);\s*(.*)/)) {
			c = RegExp.$1;
			v = 1;
		}
		var setCookieValue = function (current, value) {
			var val = value;
			if (current) {
				if (Array.isArray(current)) {
					val = current;
					val.push(value);
				} else {
					val = [current, value];
				}
			}
			return val;
		};
		if (v === 0) {
			c.split(/[,;]/).map(function (cookie) {
				var parts = cookie.split(/=/),
					name = decodeURIComponent(parts[0].trimLeft());
				parts.shift();
				var value = null;
				if (parts.length) {
					value = decodeURIComponent(parts.join('=').trimRight());
				}
				cookies[name] = setCookieValue(cookies[name], value);
			});
		} else {
			c.match(
				/(?:^|\s+)([!#$%&'*+\-.0-9A-Z^`a-z|~]+)=([!#$%&'*+\-.0-9A-Z^`a-z|~]*|"(?:[\x20-\x7E\x80\xFF]|\\[\x00-\x7F])*")(?=\s*[,;]|$)/g
			).map(function ($0, $1) {
				var value = $1.charAt(0) === '"' ? $1.substr(1, -1).replace(/\\(.)/g, '$1') : $1;
				cookies[$0] = setCookieValue(cookies[$0], value);
			});
		}
		return cookies;
	};
	/**
	 * Returns single cookie value by name. If we have multiple cookies with same name, it will return collection of values
	 * @param    {String}    name    cookie name
	 */
	this.getCookie = function (name) {
		return this.getCookies()[name];
	};
	/**
	 * Sets cookie value
	 * @param    {String}    name       cookie name
	 * @param    {String}    value      cookie value
	 * @param    {String}    path       domain path for cookie validity(/ by default - for whole domain tree)
	 * @param    {Number}    expires    number of seconds from UNIX epoch for date of expire
	 */
	this.setCookie = function (name, value, path, expires) {
		var setData = '';
		path = path && 'string' == typeof path ? path : '/';
		value = value ? value : '';
		if (name && 'string' == typeof name) {
			setData = name + '=' + encodeURIComponent(String(value)) + '; path=' + path;
			if (expires) {
				var date = new Date(expires);
				setData += '; expires=' + date.toUTCString();
			}
		}
		document.cookie = setData;
	};
	/**
	 * Initiates document sign process
	 * @param    {Object|string}      document              document to sign
	 * @param    {Object}      maskElement           element for loading mask
	 * @param    {Function}    callback              callback that will be called on success, and with passed true as param on failure
	 * @param    {String}      contentId             id of document to get content to sign, if avoided will be set to documentId
	 * @param    {Function}    beforeSign            function that should be called before sign process, and sign process should be passed as success callback to this function
	 * @param    {Boolean}     skipMaskHandling      true to avoid masking/unmasking maskElement
	 * @param    {Object}      certificateHandler    object that contains two methods - get & set, to read/write selected certificate data
	 * @param    {Object}      properties            additional properties
	 * @param    {Object}      poaHandler            object that contains two methods - get & set, to read/write selected PoA
	 */
	this.sign = function (
		document,
		maskElement,
		callback,
		contentId,
		beforeSign,
		skipMaskHandling,
		certificateHandler,
		properties,
		poaHandler
	) {
		const signProcess = new SignProcess({
			document,
			maskElement,
			callback,
			contentId,
			beforeSign,
			skipMaskHandling,
			certificateHandler,
			properties,
			poaHandler
		});
		signProcess.start();
	};
	this.getDocumentsParentType = function (document, callback) {
		var doctype = 'object' == typeof document ? document.type : undefined;
		if (doctype && edi.constants.HAS_PARENT_DOC_TYPES.some((it) => it === doctype)) {
			var i, type;
			for (i in edi.constants.PARENT_DOC_TYPES_BY_CHILDREN) {
				if (edi.constants.PARENT_DOC_TYPES_BY_CHILDREN.hasOwnProperty(i)) {
					if (edi.constants.PARENT_DOC_TYPES_BY_CHILDREN[i].some((it) => it === doctype)) {
						type = i;
						break;
					}
				}
			}
			if (type) {
				callback(type);
			} else {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
						documentId: document.id
					}),
					'GET',
					undefined,
					function (data) {
						var type = doctype;
						if (data && data.data && data.data.parent) {
							type = data.data.parent.type;
						}
						callback(type);
					},
					function () {
						callback(doctype);
					},
					undefined,
					{
						suppressDefaultError: true
					}
				);
			}
		} else {
			callback(doctype);
		}
	};
	/**
	 * Width correction for message boxes
	 * @returns {string}
	 */
	this.msgWidthCorrection = function () {
		return '<table class="msg-width-correction"><tr><td></td></tr></table>';
	};
	/**
	 * Returns direction constant
	 * @param    {Object}    [toOrg]      receiver organization
	 * @param    {Object}    [fromOrg]    sender organization
	 * @param    {Object}    [factor]     factor organization
	 * @returns  {String}
	 */
	this.getDocumentDirection = function (toOrg, fromOrg, factor) {
		var userOrgId = edi.core.getUserOrgID();
		toOrg = toOrg || {};
		fromOrg = fromOrg || {};
		factor = factor || {};
		return toOrg.id == fromOrg.id
			? edi.constants.DIRECTIONS.LOOP
			: userOrgId == toOrg.id
			? edi.constants.DIRECTIONS.INCOMING
			: userOrgId == fromOrg.id
			? edi.constants.DIRECTIONS.OUTGOING
			: factor && userOrgId == factor.id
			? edi.constants.DIRECTIONS.INCOMING_FACTOR
			: edi.constants.DIRECTIONS.UNKNOWN;
	};
	/**
	 * Calculates how many days to current/passed date we need add to get exact working days gap
	 * @param days
	 * @param date
	 * @returns {number}
	 */
	this.getWorkingDaysGap = function (days, date) {
		var diff = 0,
			dayOfWeek = date ? date.getDay() : new Date().getDay(),
			i = 0;
		while (i < days) {
			if (dayOfWeek != 6 && dayOfWeek != 0) {
				i++;
			}
			dayOfWeek++;
			if (dayOfWeek > 6) {
				dayOfWeek = 0;
			}
			diff++;
		}
		return diff;
	};
	/**
	 * Specifies the number of days to date
	 * @param ts  Date in miliseconds
	 * @returns {number}
	 */
	this.getCountDaysFromToday = function (ts) {
		var now = new Date().getTime();
		return ts <= now ? 0 : parseInt((ts - now) / edi.constants.DAY_IN_MS);
	};
	/**
	 * Gets attribute by its name
	 * @param   {Object}    attributes   attributes map by name
	 * @param   {String}    name         name of attribute to get
	 * @param   {Boolean}   [props]        get all attribute data (creationDate, modifyDate...)
	 * @param   {Boolean}   [isParse]    parse values of "false", "true", and "null" into boolean, null and other data types values
	 * @returns {*}
	 */
	this.getAttributeByName = function (attributes, name, props, isParse) {
		var value = null;
		name = String(name);
		props = !!props;
		if (name && attributes) {
			if (Array.isArray(attributes)) {
				for (var i = 0; i < attributes.length; i++) {
					var attr = attributes[i];
					if ('object' == typeof attr && attr.name == name) {
						value = props ? attr : attr.value;
						break;
					}
				}
			} else if ('object' == typeof attributes[name]) {
				value = props ? attributes[name] : attributes[name].value;
			}
		}
		if (isParse) {
			try {
				return JSON.parse(value);
			} catch (e) {
				edi.core.logMessage(`Error parsing attribute "UI Platform Maven Webapp": ${e.message}`);
			}
		}
		return value;
	};
	/**
	 * Gets hashmap of attributes values by names
	 * @param   {Object}    attributes   attributes map by name
	 * @returns {*}
	 */
	this.getAllAttributeValues = function (attributes) {
		var retVal = {},
			i;
		if (attributes) {
			if (Array.isArray(attributes)) {
				for (i = 0; i < attributes.length; i++) {
					var attr = attributes[i];
					if ('object' == typeof attributes[i]) {
						retVal[attr.name] = attr.value;
					}
				}
			} else if ('object' == typeof attributes) {
				for (i in attributes) {
					if (attributes.hasOwnProperty(i)) {
						if (attributes[i].name) {
							retVal[attributes[i].name] = attributes[i].value;
						}
					}
				}
			}
		}
		return retVal;
	};
	/**
	 * Returns translated abbrevation of unit of measure
	 * @param {String} abbr    Abbreviated unit of measure, accepts PCE, KGM or DPA
	 * @returns {String}    Unit of measure translated, abbr if unit is not found
	 */
	this.getUnitOfMeasureName = function (abbr) {
		var ret = abbr,
			record;
		if ('function' == typeof edi.stores.initOkeiStore) {
			record = edi.stores.initOkeiStore().findRecord('name_international', abbr, undefined, false, false, true);
			if (!record) {
				record = edi.stores.initOkeiStore().findRecord('name', abbr, undefined, false, false, true);
			}
			if (!record) {
				record = edi.stores.initOkeiStore().findRecord('name_international', abbr);
			}
			if (record) {
				ret = record.get('name');
			}
		}
		return ret;
	};
	/**
	 * Returns default search period for module forms
	 * @param      {String}    defaultValue    default filter value, if user did not set any value in profile yet
	 * @returns    {String}                    filter value(day, week, month) or null
	 */
	this.getUserDefaultSearchPeriod = function (defaultValue) {
		defaultValue = defaultValue ? defaultValue : null;
		var timeFilter = edi.core.getExtraData('user.defaultFilteringPeriod');
		return timeFilter ? timeFilter : defaultValue;
	};
	/**
	 * Returns default search period's field name for filters
	 * @param	{String}	defaultValue
	 * @returns	{String}	filter's field name
	 */
	this.getUserDefaultSearchPeriodField = function (defaultValue) {
		let fieldConstFromUserData = edi.core.getExtraData('user.defaultFilteringField');
		return (
			edi.constants.DEFAULT.FILTER.FIELDS[fieldConstFromUserData] ||
			defaultValue ||
			edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE
		);
	};
	/**
	 * Gets lowest possible error object
	 * @param    {Object}    data    error object
	 */
	this.getErrorObject = function (data) {
		var retData = {
			success: false
		};
		if (data) {
			retData = data;
			if (data.additionalData && data.additionalData.length) {
				if (data.additionalData[0] && data.additionalData[0].type) {
					retData.typeError = data.additionalData[0].type;
					retData.additionalData = data.additionalData[0].additionalData;
					retData = edi.utils.getErrorObject(retData);
				}
			}
		}
		return retData;
	};

	/**
	 * Reduce the filename to less (for filename with one dot)
	 * Examples
	 *   getShortFileName('very_large_filename_contains_more_then_forty_letters.ext', 3) => "ver...ers.ext"
	 *   getShortFileName('very_large_filename_contains_more_then_forty_letters', 4) => "very...ters"
	 * @param {String} str
	 * @param {Number} count
	 * @returns {String}
	 */
	this.getShortFileName = function (str, count) {
		var reg = new RegExp(
			'^([^.]{' +
				count +
				'})[^.]*([^.]{' +
				count +
				'})$|^([^.]{' +
				count +
				'})[^.]*([^.]{' +
				count +
				'})\\.([^.]*)+$',
			'i'
		);
		var data = reg.exec(str);
		if (data && data[3] !== undefined && data[4] !== undefined && data[5] !== undefined) {
			return data[3] + '...' + data[4] + '.' + data[5];
		} else if (data && data[1] !== undefined && data[2] !== undefined) {
			return data[1] + '...' + data[2];
		}
		return str;
	};

	/**
	 * Generates error message, based on server side error constant, and additional attributes for message
	 * @param   {Object}    data        object with server side data
	 * @param   {String}    defaultMsg  default message, that will be displayed on any error
	 * @param   {Boolean}   isSilent    skip logMessage in console
	 */
	this.formatComplexServerError = function (data, defaultMsg, isSilent) {
		var msg, tmp, tmpMsg, i;
		if (data) {
			if (!isSilent) edi.core.logMessage(data, 'error');

			msg = edi.i18n.getMessage(data.typeError || '');
			if (edi.constants.DEFAULT.ERROR_TYPES.find((typeError) => data.typeError === typeError)) {
				msg = `
					${edi.i18n.getMessage(data.typeError || '')}
					<br/><br/>
					<style>
						.expand-error-descr {
							cursor: pointer;
							color: #ff0000;
						}
						.hide-element {
							display: none;
						}
					</style>
					<span class="expand-error-descr" onclick="(function() {
						//т.к. MessageBox может быть на странице только один, получим его по стандартному классу
						const messageBoxExt = Ext.ComponentQuery.query('ui-messagebox')[0];
						if (!messageBoxExt) return;
						messageBoxExt.container.dom.querySelector('.expand-error-descr').classList.add('hide-element');
						messageBoxExt.container.dom.querySelector('.error-description').classList.remove('hide-element');
						messageBoxExt.updateLayout();
					}())">
						${edi.i18n.getMessage('documents.warning.selected.different.types.details')}
					</span>
					<span class="error-description hide-element">{0}</span>
				`;
			}
			if (defaultMsg && (!msg || !data.typeError || msg == data.typeError)) {
				msg = edi.i18n.getMessage(defaultMsg, data);
			} else if (data.status && (!msg || !data.typeError || msg == data.typeError)) {
				msg = edi.i18n.getMessage('error.server.' + data.status);
			}
			if (data.additionalData && data.additionalData.length) {
				if (data.additionalData[0] && data.additionalData[0].type && data.additionalData[0].additionalData) {
					tmp = data.additionalData[0];
					tmp.typeError = data.additionalData[0].type;
					tmpMsg = edi.utils.formatComplexServerError(tmp, data.typeError);
					if (tmpMsg != data.typeError) {
						msg = tmpMsg;
					}
				} else {
					for (i = 0; i < data.additionalData.length; i++) {
						if ('string' == typeof data.additionalData[i]) {
							tmpMsg = edi.i18n.getMessage(data.additionalData[i]);
							if (tmpMsg != data.additionalData[i]) {
								data.additionalData[i] = tmpMsg;
							} else {
								data.additionalData[i] = edi.utils.safeString(data.additionalData[i], true);
							}
						}
					}
					if (tmpMsg == data.typeError) {
						msg = edi.i18n.getMessage(tmpMsg);
					}
					msg = edi.error.formatErrorTemplate(msg, data.additionalData);
				}
			}
		} else if (defaultMsg) {
			msg = edi.i18n.getMessage(defaultMsg);
		}
		return msg;
	};
	/**
	 * Checks if passed object is empty
	 * @param obj
	 * @returns {boolean}
	 */
	this.isEmptyObject = function (obj) {
		var isEmpty = true,
			i;
		if (obj && Ext.isObject(obj)) {
			for (i in obj) {
				if (obj.hasOwnProperty(i)) {
					isEmpty = false;
					break;
				}
			}
		}
		return isEmpty;
	};
	/**
	 * Compares 2 objects by their property values
	 * @param objA
	 * @param objB
	 * @returns {boolean}
	 */
	this.compareObjects = function (objA, objB) {
		var equals = false,
			isEqual = function (a, b) {
				for (var i in a) {
					if (a.hasOwnProperty(i)) {
						if (b) {
							var aVal = a[i],
								bVal = b[i];
							if (Ext.isObject(aVal) || Ext.isObject(bVal)) {
								if (isEqual(aVal, bVal) !== true) {
									return false;
								}
							} else {
								if (a[i] !== b[i]) {
									return false;
								}
							}
						} else {
							return false;
						}
					}
				}
				return true;
			};
		if (Ext.isObject(objA) && Ext.isObject(objB)) {
			equals = isEqual(objA, objB) && isEqual(objB, objA);
		}
		return equals;
	};
	/**
	 * Extracts file name from passed path string
	 * @param     {String}    path    file path with name
	 * @returns   {String}
	 */
	this.getFileNameFromPath = function (path) {
		var fileName = '',
			result;
		if (path) {
			path = String(path);
			result = path.match(/[^\\/]+$/);
			if (result && result.length) {
				fileName = result[0];
			}
		}
		return fileName;
	};
	/**
	 * Extract address from organization
	 * Work like {@link #getAddressFromOrganization}, but try get anything
	 * type of address (ordering LEGAL -> REAL -> POSTAL)
	 * @param    {Object}    org    organization object
	 * @param    {String}    [type]   type of organization address
	 */
	this.getSomeAddressFromOrg = function (org, type) {
		var me = this,
			orderedTypes = [
				edi.constants.ADDRESS_TYPES.LEGAL,
				edi.constants.ADDRESS_TYPES.REAL,
				edi.constants.ADDRESS_TYPES.POSTAL
			];

		return !type
			? orderedTypes.reduce(function (acc, type) {
					var address = me.getAddressFromOrganization(org, type);
					if (address && Object.keys(acc).length === 0) {
						acc = address;
					}
					return acc;
			  }, {})
			: me.getAddressFromOrganization(org, type);
	};
	/**
	 * Extract address from organization
	 * @param	{Object}	org    organization object
	 * @param	{String}	type   type of organization address
	 * @returns	{Object|undefined}	address
	 */
	this.getAddressFromOrganization = function (org, type) {
		var address;
		if (!type) {
			type = edi.constants.ADDRESS_TYPES.LEGAL;
		}
		var oldTypes = {
			REAL: 'RU_REAL',
			LEGAL: 'RU_LEGAL'
		};
		var convertAddressType = function (address) {
			if (address.type === oldTypes[type]) {
				address.type = type;
			}
			return address;
		};
		/**
		 * temporary check for old address types
		 *
		 * @param orgAddrType
		 * @returns {boolean}
		 */
		var checkAddressType = function (orgAddrType) {
			return orgAddrType == type || orgAddrType == oldTypes[type];
		};

		if (org && org.addresses && org.addresses.length) {
			for (var i = 0; i <= org.addresses.length; i++) {
				if (org.addresses[i] && checkAddressType(org.addresses[i].type)) {
					address = convertAddressType(org.addresses[i]);
					break;
				}
			}
		}
		edi.utils.clearEmptyValues(address);
		return address;
	};
	/**
	 * Returns size of file in bytes/KB/MB/GB
	 * @param    {Number}    size    size of file in bytes
	 * @returns {*}
	 */
	this.formatFileSize = function (size) {
		var byteLimit = 1024,
			kbLimit = 1048576,
			mbLimit = 1073741824,
			gbLimit = 1099511627776,
			out;
		if (size < byteLimit) {
			if (size === 1) {
				out = '1 ' + edi.i18n.getMessage('file.size.byte');
			} else {
				out = size + ' ' + edi.i18n.getMessage('file.size.bytes');
			}
		} else if (size < kbLimit) {
			out = Math.round((size * 10) / byteLimit) / 10 + ' ' + edi.i18n.getMessage('file.size.KB');
		} else if (size < mbLimit) {
			out = Math.round((size * 10) / kbLimit) / 10 + ' ' + edi.i18n.getMessage('file.size.MB');
		} else if (size < gbLimit) {
			out = Math.round((size * 10) / mbLimit) / 10 + ' ' + edi.i18n.getMessage('file.size.GB');
		} else {
			out = Math.round((size * 10) / gbLimit) / 10 + ' ' + edi.i18n.getMessage('file.size.TB');
		}
		return out;
	};
	/**
	 * Formats date for using in search forms
	 * @param    {String}     date         date from search control
	 * @param    {String}     format       date format for conversion
	 * @param    {Number}     timeGap      gap that is needed to be added to time
	 * @param    {String}     srcFormat    date source format for conversion
	 * @param    {Boolean}    getUTC       true to eliminate local time zone difference in returning ms
	 */
	this.getSearchTime = function (date, format, timeGap, srcFormat, getUTC) {
		var time = null;
		date = new Date(edi.utils.formatDate(date, format || edi.constants.DATE_FORMAT.SERVER, srcFormat));
		if (date) {
			date = getUTC ? Ext.Date.fromUTC(date) : date;
			time = date.getTime();
			time += timeGap ? timeGap : 0;
		}
		return time;
	};
	/**
	 * Formats date for saving without timezone offset
	 * @param    {String}    date         date from search control
	 * @param    {String}    format       date format for conversion
	 * @param    {Number}    timeGap      gap that is needed to be added to time
	 * @param    {String}    srcFormat    date source format for conversion
	 */
	this.getUTCTimeZoneFree = function (date, format, timeGap, srcFormat) {
		return this.getSearchTime(date, format, timeGap, srcFormat, true);
	};
	/**
	 * Cleans string from any html content
	 * @param    {String}     input            string that must be cleaned from any html content before output to the browser
	 * @param    {Boolean}    useEncode        false to strip any tags, true to convert tags to html entities
	 * @param    {Boolean}    replaceQuotes     true to replace quotes
	 * @returns  {*}
	 */
	this.safeString = function (input, useEncode, replaceQuotes) {
		if ('string' == typeof input) {
			if (useEncode) {
				input = input.replace(replaceQuotes ? /[<>\&\"\']/g : /[<>\&]/g, function (i) {
					return '&#' + i.charCodeAt(0) + ';';
				});
			} else {
				input = Ext.String.htmlEncode(input) || '';
			}
		}
		return input;
	};
	/**
	 * Replaces all quotes in string
	 * @param    {String}     input         string that must be cleaned from quotes before output to the browser
	 * @returns  {*}
	 */
	this.replaceQuotes = function (input) {
		if ('string' == typeof input) {
			input = input.split("'").join('&#34;');
			input = input.split('"').join('&#39;');
		}
		return input;
	};
	/**
	 * make sha1 hash from string
	 * @param    {String}     str
	 * @returns  {String}
	 */
	this.sha1 = function (str) {
		//  discuss at: http://phpjs.org/functions/sha1/
		// original by: Webtoolkit.info (http://www.webtoolkit.info/)
		// improved by: Michael White (http://getsprink.com)
		// improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		//    input by: Brett Zamir (http://brett-zamir.me)
		//  depends on: utf8_encode
		//   example 1: sha1('Kevin van Zonneveld');
		//   returns 1: '54916d2e62f65b3afa6e192e6a601cdbe5cb5897'

		var rotate_left = function (n, s) {
			return (n << s) | (n >>> (32 - s));
		};

		/*var lsb_hex = function (val) { // Not in use; needed?
		 var str="";
		 var i;
		 var vh;
		 var vl;

		 for ( i=0; i<=6; i+=2 ) {
		 vh = (val>>>(i*4+4))&0x0f;
		 vl = (val>>>(i*4))&0x0f;
		 str += vh.toString(16) + vl.toString(16);
		 }
		 return str;
		 };*/

		var cvt_hex = function (val) {
			var str = '';
			var i;
			var v;

			for (i = 7; i >= 0; i--) {
				v = (val >>> (i * 4)) & 0x0f;
				str += v.toString(16);
			}
			return str;
		};

		var blockstart;
		var i, j;
		var W = new Array(80);
		var H0 = 0x67452301;
		var H1 = 0xefcdab89;
		var H2 = 0x98badcfe;
		var H3 = 0x10325476;
		var H4 = 0xc3d2e1f0;
		var A, B, C, D, E;
		var temp;

		str = edi.utils.utf8_encode(str);
		var str_len = str.length;

		var word_array = [];
		for (i = 0; i < str_len - 3; i += 4) {
			j =
				(str.charCodeAt(i) << 24) |
				(str.charCodeAt(i + 1) << 16) |
				(str.charCodeAt(i + 2) << 8) |
				str.charCodeAt(i + 3);
			word_array.push(j);
		}

		switch (str_len % 4) {
			case 0:
				i = 0x080000000;
				break;
			case 1:
				i = (str.charCodeAt(str_len - 1) << 24) | 0x0800000;
				break;
			case 2:
				i = (str.charCodeAt(str_len - 2) << 24) | (str.charCodeAt(str_len - 1) << 16) | 0x08000;
				break;
			case 3:
				i =
					(str.charCodeAt(str_len - 3) << 24) |
					(str.charCodeAt(str_len - 2) << 16) |
					(str.charCodeAt(str_len - 1) << 8) |
					0x80;
				break;
		}

		word_array.push(i);

		while (word_array.length % 16 != 14) {
			word_array.push(0);
		}

		word_array.push(str_len >>> 29);
		word_array.push((str_len << 3) & 0x0ffffffff);

		for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
			for (i = 0; i < 16; i++) {
				W[i] = word_array[blockstart + i];
			}
			for (i = 16; i <= 79; i++) {
				W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
			}

			A = H0;
			B = H1;
			C = H2;
			D = H3;
			E = H4;

			for (i = 0; i <= 19; i++) {
				temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) & 0x0ffffffff;
				E = D;
				D = C;
				C = rotate_left(B, 30);
				B = A;
				A = temp;
			}

			for (i = 20; i <= 39; i++) {
				temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff;
				E = D;
				D = C;
				C = rotate_left(B, 30);
				B = A;
				A = temp;
			}

			for (i = 40; i <= 59; i++) {
				temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8f1bbcdc) & 0x0ffffffff;
				E = D;
				D = C;
				C = rotate_left(B, 30);
				B = A;
				A = temp;
			}

			for (i = 60; i <= 79; i++) {
				temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff;
				E = D;
				D = C;
				C = rotate_left(B, 30);
				B = A;
				A = temp;
			}

			H0 = (H0 + A) & 0x0ffffffff;
			H1 = (H1 + B) & 0x0ffffffff;
			H2 = (H2 + C) & 0x0ffffffff;
			H3 = (H3 + D) & 0x0ffffffff;
			H4 = (H4 + E) & 0x0ffffffff;
		}

		temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
		return temp.toLowerCase();
	};
	/**
	 * Encode utf-8
	 * @param {string} argString
	 * @returns {string}
	 */
	this.utf8_encode = function (argString) {
		//  discuss at: http://phpjs.org/functions/utf8_encode/
		// original by: Webtoolkit.info (http://www.webtoolkit.info/)
		// improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// improved by: sowberry
		// improved by: Jack
		// improved by: Yves Sucaet
		// improved by: kirilloid
		// bugfixed by: Onno Marsman
		// bugfixed by: Onno Marsman
		// bugfixed by: Ulrich
		// bugfixed by: Rafal Kukawski
		// bugfixed by: kirilloid
		//   example 1: utf8_encode('Kevin van Zonneveld');
		//   returns 1: 'Kevin van Zonneveld'

		if (argString === null || typeof argString === 'undefined') {
			return '';
		}

		var string = argString + ''; // .replace(/\r\n/g, "\n").replace(/\r/g, "\n");
		var utftext = '',
			start = 0,
			end = 0,
			stringl = string.length;

		for (var n = 0; n < stringl; n++) {
			var c1 = string.charCodeAt(n);
			var enc = null;

			if (c1 < 128) {
				end++;
			} else if (c1 > 127 && c1 < 2048) {
				enc = String.fromCharCode((c1 >> 6) | 192, (c1 & 63) | 128);
			} else if ((c1 & 0xf800) != 0xd800) {
				enc = String.fromCharCode((c1 >> 12) | 224, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128);
			} else {
				// surrogate pairs
				if ((c1 & 0xfc00) != 0xd800) {
					throw new RangeError('Unmatched trail surrogate at ' + n);
				}
				var c2 = string.charCodeAt(++n);
				if ((c2 & 0xfc00) != 0xdc00) {
					throw new RangeError('Unmatched lead surrogate at ' + (n - 1));
				}
				c1 = ((c1 & 0x3ff) << 10) + (c2 & 0x3ff) + 0x10000;
				enc = String.fromCharCode(
					(c1 >> 18) | 240,
					((c1 >> 12) & 63) | 128,
					((c1 >> 6) & 63) | 128,
					(c1 & 63) | 128
				);
			}
			if (enc !== null) {
				if (end > start) {
					utftext += string.slice(start, end);
				}
				utftext += enc;
				start = end = n + 1;
			}
		}

		if (end > start) {
			utftext += string.slice(start, stringl);
		}

		return utftext;
	};
	/**
	 * Class for Dom object animations
	 * @param config
	 * @returns {{stop: Function}}
	 */
	this.animate = function (config) {
		config = 'object' == typeof config ? config : {};
		var defaults = {
			duration: 500,
			progress: undefined,
			start: undefined,
			finish: undefined
		};
		Ext.applyIf(config, defaults);
		'function' == typeof config.start ? config.start(0, 0) : null;
		var isStopped = false;
		var animationObject = {
			duration: config.duration,
			update: function (obj) {
				if (isStopped) {
					edi.utils.animation.removeObject(animationObject);
				} else {
					'function' == typeof config.progress ? config.progress(obj.progress) : null;
					if (obj.progress == 1) {
						'function' == typeof config.finish ? config.finish(obj.progress) : null;
					}
				}
			}
		};
		edi.utils.animation.addObject(animationObject);
		return {
			stop: function () {
				isStopped = true;
			}
		};
	};
	this.calculateState = function (startValue, endValue, progress) {
		return startValue + (endValue - startValue) * progress;
	};

	/**
	 * Copies text content of provided dom element to clipboard
	 * @param    {Object}    domElm     browser dom model element
	 */
	this.copyToClipboard = function (domElm) {
		if (domElm) {
			var selection = window.getSelection();
			selection.removeAllRanges();
			var range = document.createRange();
			range.selectNode(domElm);
			selection.addRange(range);
			document.execCommand('copy');
			selection.removeAllRanges();
		}
	};
	/**
	 * Creates visual representation of object serialization in json
	 * @param    {Object}    obj            object to display
	 * @param    {Boolean}   doNotEncode    true to skip serialization
	 * @param    {Object}    additional     object with additional processing parameters
	 */
	this.stringifyObject = function (obj, doNotEncode, additional) {
		var result = edi.utils
			.safeString(doNotEncode ? obj : Ext.encode(obj), true)
			.replace(/,("|\{|\[)/g, ',<br />$1')
			.replace(/([^\\]":(?! ))/g, '$1 ')
			.replace(/{"/g, '{<div class="edi-test-result">"')
			.replace(/\[({|")/g, '[<div class="edi-test-result">$1')
			.replace(/([^\\]"|\}|\]|(?:": \w+))\}/g, '$1</div>}')
			.replace(/(\}|")\]/g, '$1</div>]')
			.replace(/\}\}/g, '}</div>}');
		if (additional && additional.useTabs) {
			result = result
				.replace(/<br \/>/g, '\r\n')
				.replace(/(<div class\="edi-test-result">)/g, '\r\n$1\r\n')
				.replace(/(<\/div>)/g, '\r\n$1\r\n');
			var splitted = result.split(/\r\n/g),
				tabCounter = 0,
				i,
				replaced,
				multiply = function () {
					var retStr = '';
					for (var i = 0; i < tabCounter; i++) {
						retStr += '\t';
					}
					return retStr;
				};
			for (i = 0; i < splitted.length; i++) {
				replaced = splitted[i].replace('<div class="edi-test-result">', '');
				if (replaced != splitted[i]) {
					tabCounter++;
				} else {
					replaced = splitted[i].replace('</div>', '');
					if (replaced != splitted[i]) {
						tabCounter--;
					}
				}
				if (replaced) {
					splitted[i] = multiply() + replaced;
				} else {
					splitted[i] = '';
				}
			}
			splitted = splitted.filter(function (val) {
				return !!val;
			});
			result = splitted.join('\r\n');
			result = "<pre class='edi-test-result-container'>" + result + '</pre>';
		}
		return result;
	};
	/**
	 * Возвращает количество полных лет, месяцев и дней между двумя датами
	 * @param    {Number}    startDate
	 * @param    {Number}   endDate
	 */
	this.getDiffBetweenTwoDate = function (startDate, endDate) {
		const startDay = startDate.getDate();
		const endDay = endDate.getDate();
		const endDateFullYear = endDate.getFullYear();
		const endDateMonth = endDate.getMonth() + 1;
		const diffYears = Ext.Date.diff(startDate, endDate, 'y');
		let diffMonth = Ext.Date.diff(startDate, endDate, 'mo');
		const diffDays = Ext.Date.diff(startDate, endDate, 'd');

		const getDaysInMonth = function (month, year) {
			return new Date(year, month, 0).getDate();
		};

		const daysInPrevMonth = getDaysInMonth(endDateMonth - 1, endDateFullYear);
		let days = diffDays;
		if (diffDays >= daysInPrevMonth) {
			if (startDay <= endDay) {
				days = endDay - startDay;
			} else {
				days = daysInPrevMonth - startDay + endDay;
			}
		}

		if (diffMonth >= 12) {
			diffMonth = diffMonth - diffYears * 12;
		}

		return { diffYears: diffYears, diffMonth: diffMonth, diffDays: days };
	};
	/**
	 * Animation handling
	 */
	this.animation = new (function () {
		var _self = this,
			config = {
				updateTime: new Date().getTime(),
				updateDelay: 1000 / 60
			},
			updateActive = false;
		this.deltaTime = 0;
		var objects = [];
		this.objects = objects;
		/**
		 * process animation progress
		 * @param {number} startTime
		 * @param {number} durationTime
		 */
		this.processProgress = function (startTime, durationTime) {
			durationTime = durationTime ? durationTime : 1;
			var progress = (new Date().getTime() - startTime) / durationTime;
			if (progress > 1) {
				progress = 1;
			}
			return progress;
		};
		/**
		 * add object to animation
		 * @param    {Object}    obj
		 */
		this.addObject = function (obj) {
			if (obj) {
				obj.id = obj.id ? obj.id : edi.core.getId();
				obj.startTime = new Date().getTime();
				obj.duration = obj.duration ? obj.duration : 0;
				objects.push(obj);
				if (!updateActive) {
					updateActive = true;
					setTimeout(update, 0);
				}
			}
		};
		/**
		 * remove object from animation
		 * @param    {Object}    obj
		 */
		this.removeObject = function (obj) {
			if (obj) {
				for (var i = 0; i < objects.length; i++) {
					if (objects[i].id == obj.id) {
						objects.splice(i, 1);
						break;
					}
				}
			}
		};
		/**
		 * update loop method
		 */
		var update = function () {
			var theTime = new Date().getTime(),
				toRemove = [],
				i;
			for (i = 0; i < objects.length; i++) {
				var obj = objects[i];
				if ('number' == typeof obj.startTime && 'number' == typeof obj.duration) {
					obj.progress = _self.processProgress(obj.startTime, obj.duration);
				}
				if (obj.progress > 1) {
					obj.progress = 1;
				}
				'function' == typeof objects[i].update ? objects[i].update(obj) : null;
				if (obj.progress == 1) {
					toRemove.push(obj);
				}
			}
			if (toRemove.length) {
				for (i = 0; i < toRemove.length; i++) {
					_self.removeObject(toRemove[i]);
				}
			}
			if (objects.length) {
				var dif = new Date().getTime() - config.updateTime;
				_self.deltaTime = dif / 1000;
				_self.ups = 1000 / dif;
				if (_self.deltaTime > 0.1) {
					_self.deltaTime = 0.1;
				}
				config.updateTime = new Date().getTime();
				var delay = new Date().getTime() - theTime;
				if (config.updateDelay <= delay) {
					delay = config.updateDelay;
				}
				setTimeout(update, config.updateDelay - delay);
			} else {
				updateActive = false;
			}
		};
	})();
})();
